import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import app_config from "../../config";
import { fetchStarupStageLabel } from "../../Constants";
import { convertToReadableDate } from "../../util";
import AlphabetAvatar from "../AlphabetAvatar";
import { findCountryLabel } from "../commons/countries";
import { checkProfileImageExist } from "../../util.js";
import MyNetwork from "./MyNetwork";
import { useNavigate } from "react-router-dom";

export default function MyProfile() {
  const { id } = useParams();
  const navigate = useNavigate();

  // console.log(id);

  const [startupData, setStartupData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("profile");
  const [networkCount, setNetworkCount] = useState(0);
  const [approvedRequests, setApprovedRequests] = useState([]);
  const [pendingRequests, setPendingRequests] = useState([]);

  const fetchProfileById = async () => {
    setLoading(true);
    const res = await fetch(`${app_config.apiurl}/users/getbyid/${id}`);
    const data = await res.json();
    console.log(data);
    setStartupData(data.result);
    setLoading(false);
  };

  const fetchApprovedRequests = async () => {
    const token = sessionStorage.getItem("token");
    try {
      const response = await fetch(`${app_config.apiurl}/users/connections`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setApprovedRequests(data.result);
      setNetworkCount(data.result.length);
    } catch (error) {
      console.error("Error fetching approved requests:", error);
    }
  };

  const fetchPendingRequests = async () => {
    const token = sessionStorage.getItem("token");
    try {
      const response = await fetch(
        `${app_config.apiurl}/users/connections?status=pending`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const data = await response.json();
      setPendingRequests(data.result); // Store pending requests
    } catch (error) {
      console.error("Error fetching pending requests:", error);
    }
  };

  useEffect(() => {
    fetchProfileById();
    fetchApprovedRequests();
    fetchPendingRequests(); // Fetch pending requests on component mount
  }, []);

  const displayDetails = () => (
    <div>
      <div className=" h-[15rem] md:h-[10rem] flex flex-col">
        <div className="border-b pb-2 flex justify-center md:justify-start items-center space-x-4 mt-24 md:mt-12 ml-8 md:ml-12">
          <button
            className={`px-4 py-2 font-medium ${activeTab === "profile" ? "bg-black text-white rounded-lg" : "bg-transparent text-black"}`}
            onClick={() => setActiveTab("profile")}
          >
            Profile
          </button>
          <button
            className={`relative px-6 pr-10 py-2 font-medium ${activeTab === "myNetwork" ? "bg-black text-white rounded-lg" : "bg-transparent text-black"}`}
            onClick={() => setActiveTab("myNetwork")}
          >
            My Network
            <span className="absolute top-2 right-2 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-black bg-white rounded-full">
              {networkCount}
            </span>
          </button>
        </div>
      </div>
      <div className="sm:pl-7 sm:pr-20 mt-[-4rem] md:-mt-8 max-w-6xl flex flex-col sm:flex-row">
        {activeTab === "profile" && (
          <>
            <div className="col-md-4 mb-4">
              <div className="flex flex-col gap-2 items-center py-6 sm:p-6 bg-[#fff] mx-4 rounded-xl shadow-xl relative">
                {/* Notification message with icon */}
                <div className="absolute top-2 left-2 mr-2 flex items-center gap-1 px-3 py-1 bg-[#f3f4f6] text-black text-xs rounded-full shadow-md">
                  <i className="fas fa-eye"></i>
                  <span>Your profile is visible to others</span>
                </div>

                <div className="mt-4">
                  {checkProfileImageExist(startupData.startupimage) ? (
                    <img
                      className="w-32 h-32 rounded-full"
                      src={startupData.startupimage}
                      alt="avatar"
                    />
                  ) : (
                    <AlphabetAvatar alphabet={startupData.name.charAt(0)} />
                  )}
                </div>
                <div className="flex flex-col gap-2 items-center">
                  <h2 className="text-3xl pt-3 font-medium text-center">
                    {startupData.name}
                  </h2>
                  <p className="text-center">
                    <i className="fas fa-quote-left"></i> {startupData.brief}
                  </p>
                  <button
                    className="flex items-center gap-2 px-4 py-2 mt-2 bg-skincolor text-black font-medium rounded-lg"
                    onClick={() => navigate("/startup/profile")}
                  >
                    <i className="fas fa-edit"></i> Edit
                  </button>
                </div>
              </div>
            </div>

            <div className="col-md-8 px-6 bg-[#fff] shadow-xl rounded-xl mb-4 mx-4">
              {/* Basic information */}
              <div>
                <div className="py-2 border-b border-[#d3cdcd]">
                  <h2 className="font-medium text-[19px]">
                    Startup Information
                  </h2>
                </div>
                <div className="flex flex-col sm:flex-row">
                  <div className="sm:w-[55%]">
                    <div className="bg-[#fff] rounded-xl p-3">
                      <div className="flex flex-col gap-2">
                        <p className="text-[#a3a0a0]">Startup Type</p>
                        <p className="font-medium">
                          {startupData.type || "Not Available"}
                        </p>
                      </div>
                    </div>
                    <div className="bg-[#fff] rounded-xl p-3">
                      <div className="flex flex-col gap-2">
                        <p className="text-[#a3a0a0]">Identity Number</p>
                        <p className="font-medium">
                          {startupData.identityno || "Not Available"}
                        </p>
                      </div>
                    </div>
                    <div className="bg-[#fff] rounded-xl p-3">
                      <div className="flex flex-col gap-2">
                        <p className="text-[#a3a0a0]">Startup stages</p>
                        <p className="font-medium">
                          {fetchStarupStageLabel(startupData.startupStages)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="sm:w-[45%]">
                    <div className="bg-[#fff] rounded-xl p-3">
                      <div className="flex flex-col gap-2">
                        <p className="text-[#a3a0a0]">App name</p>
                        <p className="font-medium">
                          {startupData.productname || "Not Available"}
                        </p>
                      </div>
                    </div>
                    <div className="bg-[#fff] rounded-xl p-3">
                      <div className="flex flex-col gap-2">
                        <p className="text-[#a3a0a0]">Current Raised Amount</p>
                        <p className="font-medium">
                          {startupData.currentRaiseAmount || "Not Available"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="py-2 border-b border-[#d3cdcd]">
                  <h2 className="font-medium text-[19px]">Address</h2>
                </div>
                <div className="flex flex-col sm:flex-row">
                  <div className="sm:w-[55%]">
                    <div className="bg-[#fff] rounded-xl p-3">
                      <div className="flex flex-col gap-2">
                        <p className="text-[#a3a0a0]">City</p>
                        <p className="font-medium">
                          {startupData.city || "Not Available"}
                        </p>
                      </div>
                    </div>
                    <div className="bg-[#fff] rounded-xl p-3">
                      <div className="flex flex-col gap-2">
                        <p className="text-[#a3a0a0]">State</p>
                        <p className="font-medium">
                          {startupData.state || "Not Available"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="sm:w-[45%]">
                    <div className="bg-[#fff] rounded-xl p-3">
                      <div className="flex flex-col gap-2">
                        <p className="text-[#a3a0a0]">Location</p>
                        <p className="font-medium">
                          {findCountryLabel(startupData.location) ||
                            "Not Available"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="py-2 border-b border-[#d3cdcd]">
                  <h2 className="font-medium text-[19px]">Basic Information</h2>
                </div>
                <div className="flex flex-col sm:grid sm:grid-cols-2 sm:gap-4 ml-2 mb-4">
                  <div className="bg-[#fff] rounded-xl p-2">
                    <div className="flex flex-col gap-2">
                      <p className="text-[#a3a0a0]">Email</p>
                      <p
                        className="font-medium break-words"
                        style={{ wordBreak: "break-word" }}
                      >
                        {startupData.email}
                      </p>
                    </div>
                  </div>
                  <div className="bg-[#fff] md:ml-8 rounded-xl p-2">
                    <div className="flex flex-col gap-2">
                      <p className="text-[#a3a0a0]">LinkedIn URL</p>
                      <a
                        href={startupData.linkedInUrl || "#"}
                        target="_blank"
                        className="font-medium overflow-hidden whitespace-nowrap"
                        style={{
                          maxWidth: "300px",
                          textOverflow: "ellipsis",
                          display: "block",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {startupData.linkedInUrl}
                      </a>
                    </div>
                  </div>
                  <div className="bg-[#fff] rounded-xl p-2">
                    <div className="flex flex-col gap-2">
                      <p className="text-[#a3a0a0]">Phone Number</p>
                      <p className="font-medium">
                        {startupData.tel || "Not Available"}
                      </p>
                    </div>
                  </div>
                  <div className="bg-[#fff] md:ml-8 rounded-xl p-2">
                    <div className="flex flex-col gap-2">
                      <p className="text-[#a3a0a0]">Website</p>
                      <p className="font-medium">
                        {startupData.website || "Not Available"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {activeTab === "myNetwork" && (
          <MyNetwork
            approvedRequests={approvedRequests}
            pendingRequests={pendingRequests}
          />
        )}
      </div>
    </div>
  );

  return (
    <>
      {loading ? (
        <div className="text-center mt-64">
          <CircularProgress color="success" />
        </div>
      ) : (
        startupData && displayDetails()
      )}
    </>
  );
}
