import React, { useState } from "react";
import { Formik, Form } from "formik";
import { motion } from "framer-motion";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Box, Stepper, Step, StepLabel } from "@mui/material";
import Swal from "sweetalert2";
import { MDBProgress, MDBProgressBar } from "mdb-react-ui-kit";
import { Check } from "@mui/icons-material"; // Import Check icon for completed steps
import {
  Business,
  LocationOn,
  Description,
  MonetizationOn,
} from "@mui/icons-material";
import Step0ProfileDetails from "./Step0ProfileDetails";
import Step1IndustryLocation from "./Step1IndustryLocation";
import Step2BriefStage from "./Step2BriefStage";
import Step3InterestsFunding from "./Step3InterestsFunding";
import app_config from "../../config";

const steps = [
  { label: "Profile Details", icon: <Business /> },
  { label: "Industry & Location", icon: <LocationOn /> },
  { label: "Brief & Stage", icon: <Description /> },
  { label: "Interests & Funding", icon: <MonetizationOn /> },
];

// Define validation schemas for each step
const validationSchemas = [
  Yup.object({
    startupimage: Yup.mixed().required("Startup image is required"),
    name: Yup.string().required("Startup name is required"),
  }),
  Yup.object({
    industry: Yup.string().required("Industry is required"),
    location: Yup.string().required("Location is required"),
  }),
  Yup.object({
    brief: Yup.string()
      .required("Description is required")
      .test(
        "wordCount",
        "Description cannot exceed 150 words",
        (value) => value && value.split(/\s+/).length <= 150,
      ),
    startupStages: Yup.string().required("At least one stage must be selected"),
  }),
  Yup.object({
    interests: Yup.array()
      .min(1, "At least one interest is required")
      .required("Please select at least one interest"),
    type: Yup.string().required("Funding type is required"),
  }),
];

// Custom StepIcon Component
const StepIcon = (props) => {
  const { active, completed, icon } = props;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 40,
        height: 40,
        borderRadius: "50%",
        backgroundColor: active || completed ? "#b0ff4b" : "#E0E0E0", // Skin color when active or completed
        color: active || completed ? "#000" : "#9E9E9E", // Icon color when active/completed vs default
      }}
    >
      {completed ? <Check /> : icon}
    </div>
  );
};

const StartupQuestionnaire = () => {
  const [step, setStep] = useState(-1);
  const currentUser = JSON.parse(sessionStorage.getItem("startup"));
  const navigate = useNavigate();

  const onSubmit = async (values) => {
    try {
      const imageUrls = values.startupimage
        ? await uploadImage(values.startupimage)
        : Promise.resolve(null);
      values.startupimage = imageUrls;

      const { imagePreview, ...formData } = values;

      // Ensure interests is an array and convert it to a string for submission if needed
      formData.interests = formData.interests.join(", "); // Convert array to comma-separated string for submission

      // Update user
      await updateUser(formData, { setSubmitting: () => {} });
    } catch (error) {
      console.error("Error submitting form:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while submitting the form.",
      });
    }
  };

  const handleFileChange = (setFieldValue, event) => {
    const file = event.target.files[0];
    setFieldValue("startupimage", file);

    // Create a preview URL for the image
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setFieldValue("imagePreview", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleStageSelection = (value, setFieldValue) => {
    setFieldValue("startupStages", value);
  };

  const renderStepContent = (
    step,
    setFieldValue,
    values,
    handleChange,
    errors,
    touched,
  ) => {
    switch (step) {
      case 0:
        return (
          <Step0ProfileDetails
            handleFileChange={handleFileChange}
            {...{ setFieldValue, values, handleChange, errors, touched }}
          />
        );
      case 1:
        return (
          <Step1IndustryLocation
            {...{ setFieldValue, values, handleChange, errors, touched }}
          />
        );
      case 2:
        return (
          <Step2BriefStage
            handleStageSelection={handleStageSelection}
            {...{ setFieldValue, values, handleChange, errors, touched }}
          />
        );
      case 3:
        return (
          <Step3InterestsFunding
            {...{ setFieldValue, values, handleChange, errors, touched }}
          />
        );
      default:
        return null;
    }
  };

  const handleNext = async (validateForm, setTouched, values, errors) => {
    // Get the fields related to the current step
    const stepFields = Object.keys(validationSchemas[step].fields);

    // Mark only the current step fields as touched
    setTouched(
      stepFields.reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {}),
    );

    // Validate the form and filter for current step fields
    const validationErrors = await validateForm();
    const stepErrors = Object.keys(validationErrors).filter((key) =>
      stepFields.includes(key),
    );

    // If no errors for the current step, proceed to the next step
    if (stepErrors.length === 0) {
      setStep((prevStep) => prevStep + 1);
    } else {
      console.log("Validation failed for current step");
    }
  };

  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const uploadImage = async (file) => {
    const fd = new FormData();
    fd.append("myfile", file);
    const res = await fetch(`${app_config.apiurl}/util/uploadfile`, {
      method: "POST",
      body: fd,
    });
    const response = await res.json();
    return res.status === 200 ? response.url : null;
  };

  const updateUser = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      const token = sessionStorage.getItem("token");

      const res = await fetch(
        `${app_config.apiurl}/users/update/${currentUser._id}`,
        {
          method: "PUT",
          body: JSON.stringify(values),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      setSubmitting(false);

      if (res.status === 200) {
        const data = await res.json();
        sessionStorage.setItem("startup", JSON.stringify(data.result));
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Saved Successfully",
        });
        navigate("/startup/investorlist");
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please fill the required details",
        });
      }
    } catch (error) {
      console.error("Error updating user:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while updating the user.",
      });
    }
  };

  return (
    <div className="bg-white h-[100vh]">
      <header className="md:h-[12vh] h-[8vh] flex justify-center items-center">
        <img
          src="/logo.png"
          alt="Logo"
          className="md:h-44 h-28 w-auto md:!mt-0 mt-4"
        />
      </header>
      <div className="p-3">
        <div className="flex justify-center w-full">
          <MDBProgress height="20">
            <MDBProgressBar
              width={(step + 1) * 25}
              valuemin={0}
              valuemax={100}
            />
          </MDBProgress>
        </div>

        {step === -1 ? (
          <div className="flex justify-center items-center h-[70vh] px-4">
            <motion.div
              className="w-full md:w-1/3 text-center"
              initial={{ opacity: 0, y: 50 }} // Starting animation properties
              animate={{ opacity: 1, y: 0 }} // End animation
              transition={{ duration: 1 }} // Duration of animation
            >
              <h2 className="text-2xl font-semibold text-black mb-4">
                Welcome <span className="capitalize">{currentUser.name}</span>{" "}
                to the Startup Questionnaire!
              </h2>
              <p className="mb-2">
                Let's gather some information about your startup. Click
                "Continue" to get started.
              </p>
              <motion.button
                className="inline-flex items-center border border-transparent text-sm font-medium shadow-sm bg-skincolor text-black px-4 py-2 rounded"
                onClick={() => setStep(0)}
                whileHover={{ scale: 1.1 }} // Add hover animation
                whileTap={{ scale: 0.95 }} // Add tap animation
              >
                Continue
              </motion.button>
            </motion.div>
          </div>
        ) : (
          <Formik
            initialValues={{
              ...currentUser,
              interests: Array.isArray(currentUser.interests)
                ? currentUser.interests // If it's already an array, use it as is
                : currentUser.interests
                  ? currentUser.interests.split(", ") // If it's a string, split it into an array
                  : [], // If it's undefined or null, initialize as an empty array
            }}
            validationSchema={validationSchemas[step]}
            onSubmit={onSubmit}
          >
            {({
              values,
              setFieldValue,
              handleChange,
              errors,
              touched,
              validateForm,
              setTouched,
              isSubmitting, // Add isSubmitting here
            }) => (
              <Form>
                <div className="flex justify-center">
                  <Box
                    sx={{
                      width: {
                        xs: "100%", // width for mobile devices
                        md: "50%", // width for medium and larger devices
                      },
                    }}
                  >
                    <Stepper activeStep={step} alternativeLabel>
                      {steps.map((stepItem, index) => (
                        <Step key={index}>
                          <StepLabel
                            StepIconComponent={StepIcon}
                            icon={stepItem.icon}
                          >
                            {stepItem.label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Box>
                </div>
                <div className="flex justify-center items-center h-[65vh] px-4 md:-mt-10 -mt-16">
                  <div className="w-full md:w-1/3">
                    {renderStepContent(
                      step,
                      setFieldValue,
                      values,
                      handleChange,
                      errors,
                      touched,
                    )}
                    <div className="flex items-center justify-between mt-8">
                      {step > 0 && (
                        <button
                          type="button"
                          className="bg-skincolor text-black px-4 py-2 rounded text-sm font-medium shadow-sm"
                          onClick={handleBack}
                        >
                          Back
                        </button>
                      )}
                      {step < steps.length - 1 ? (
                        <button
                          type="button"
                          className="bg-skincolor text-black px-4 py-2 rounded text-sm font-medium shadow-sm"
                          onClick={() => handleNext(validateForm, setTouched)}
                        >
                          Next
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="bg-skincolor text-black px-4 py-2 rounded text-sm font-medium shadow-sm"
                          disabled={isSubmitting} // Disable button during submission
                        >
                          {isSubmitting ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default StartupQuestionnaire;
