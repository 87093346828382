import React from "react";
import { Link } from "react-router-dom";

const ObjectiveDetail = () => {
  return (
    <>
      <div
        className="flex flex-col md:flex-row items-center justify-center p-3 md:mt-5 -ml-12 md:-ml-36"
        style={{ backgroundColor: "white" }}
      >
        <div className="col-md-3 ml-16 md:ml-0 mb-3">
          <img
            src="/Kreedify_pro_list_mobileMockup.png"
            alt="kreedify pro list mockup"
            className="h-[400px] md:h-[30rem]"
          />
        </div>
        <div className="ml-12 md:-ml-20 md:mt-0 text-center md:!text-left">
          <p className="py-2 text-black font-semibold text-4xl ml-4 md:ml-0">
            Raise funding more effectively
          </p>

          <p className="py-2 -ml-12 text-black text-xl ml-4 md:ml-0">
            ‘Raising capital can be so difficult without the network.
            <br className="hidden sm:block"></br>
            Not anymore!<br className="hidden sm:block"></br>
            We make it easier for founders to grow their network &{" "}
            <br className="hidden sm:block"></br>
            make fundraising more easy going’
            <br className="hidden sm:block"></br>
          </p>
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center justify-center p-3 md:!p-5 md:mt-5 -ml-12 md:ml-12">
        <div className="col-md-3 ml-16 md:ml-0 mb-3 md:hidden">
          <img
            src="/need.png"
            alt="kreedify pro list mockup"
            className="h-[300px] md:h-[30rem]"
          />
        </div>
        <div className="ml-12 md:ml-0 md:mt-0 text-center md:!text-left">
          <h2 className="font-semibold text-black text-4xl py-3">
            Leverage your network
          </h2>
          <p className="py-2 text-black text-xl">
            ‘Our aim is to amplify using network effects.{" "}
            <br className="hidden sm:block"></br>
            Anyone who has access to investors can join us as a scout to{" "}
            <br className="hidden sm:block"></br>
            help founders! We simply increase the chances for startups to{" "}
            <br className="hidden sm:block"></br>
            raise funds’
          </p>
          <Link
            className="btn normal-case font-bold mt-2 md:mt-8 text-sm"
            style={{ backgroundColor: "#b0ff4b", color: "black" }}
            to="/main/signup"
          >
            Free Signup
          </Link>
        </div>
        <div className="col-md-3 ml-20 mb-3 hidden md:block">
          <img
            src="/need.png"
            alt="kreedify pro list mockup"
            className="h-[300px]"
          />
        </div>
      </div>
    </>
  );
};

export default ObjectiveDetail;
