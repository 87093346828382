import React from "react";
import { countries } from "../commons/countries";
import { startupIndustries } from "../../Constants";

const Step1IndustryLocation = ({ values, handleChange, errors, touched }) => {
  return (
    <>
      <div>
        <label
          htmlFor="industry"
          className="text-lg font-semibold text-black mb-2"
        >
          Your Industry
        </label>
        <br />
        <select
          className="select border border-gray-300 rounded-md px-2 py-2 w-full focus:outline-none focus:border-blue-500"
          name="industry"
          value={values.industry}
          onChange={handleChange}
        >
          <option value="">Select Industry</option>
          {startupIndustries.map((ind) => (
            <option key={ind.value} value={ind.value}>
              {ind.text}
            </option>
          ))}
        </select>
        {errors.industry && touched.industry ? (
          <div className="text-deny">{errors.industry}</div>
        ) : null}
      </div>

      <div className="form-outline mt-8">
        <label
          htmlFor="location"
          className="text-lg font-semibold text-black mb-2"
        >
          Location
        </label>
        <select
          id="location"
          name="location"
          onChange={handleChange}
          value={values.location}
          className="bg-gray-50 border border-black-900 text-gray-900 text-sm rounded-lg block w-full p-2.5"
        >
          <option value="">Location</option>
          {countries.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.name}
              </option>
            );
          })}
        </select>
        {errors.location && touched.location ? (
          <div className="text-deny">{errors.location}</div>
        ) : null}
      </div>
    </>
  );
};

export default Step1IndustryLocation;
