import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import app_config from "../../config";

const NewsDetail = () => {
  const { id } = useParams();
  const [newsData, setNewsData] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchNewsById = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${app_config.apiurl}/News/news-events/${id}`,
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      if (data && data.result) {
        let selectedArticle = data.result;
        setNewsData({
          heading: selectedArticle.title,
          description: selectedArticle.descriptiom,
          image: selectedArticle.image,
          content: selectedArticle.body,
        });
      } else {
        throw new Error("Invalid response format");
      }
    } catch (error) {
      console.error("Error fetching news detail:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNewsById();
  }, [id]);

  const capitalizeFirstLetter = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const formatContent = (content) => {
    const sentences = content.split(". ");
    const paragraphCount = 4; // or 5
    const sentencesPerParagraph = Math.ceil(sentences.length / paragraphCount);

    let formattedContent = [];
    for (let i = 0; i < paragraphCount; i++) {
      let paragraph = sentences
        .slice(i * sentencesPerParagraph, (i + 1) * sentencesPerParagraph)
        .join(". ");
      if (i < paragraphCount - 1) {
        paragraph += ".";
      }
      paragraph = capitalizeFirstLetter(paragraph.trim());
      formattedContent.push(paragraph);
    }
    return formattedContent;
  };

  const displayDetails = () => {
    if (loading) {
      return (
        <div className="text-center mt-64">
          <h1 style={{ color: "#518f3d" }}>Loading...</h1>
        </div>
      );
    }
    if (newsData) {
      const formattedContent = formatContent(newsData.content);
      return (
        <>
          {/* <div
            style={{ backgroundColor: "#b0ff4b" }}
            className="-mb-52 lg:-mb-52 h-[20rem]"
          ></div> */}
          <div className="lg:m-16 m-4 p-4 lg:p-5 card shadow-none">
            <div className="xl:mx-52">
              <h1 className="xl:text-5xl text-xl py-4 text-black xl:leading-tight font-bold">
                {newsData.heading}
              </h1>
              <p className="mb-4 font-medium text-sm xl:leading-relaxed xl:text-lg">
                {newsData.description}
              </p>
              <hr className="mb-4" />

              <img
                src={newsData.image}
                className="img-fluid mx-auto mb-5"
                style={{ display: "block", maxHeight: "300px" }}
                alt={newsData.heading}
              />
              {formattedContent.map((paragraph, index) => (
                <p
                  key={index}
                  className="text-black xl:leading-loose sm:text-lg mb-3"
                >
                  {paragraph}
                </p>
              ))}
            </div>
          </div>
        </>
      );
    }
    return null;
  };

  return displayDetails();
};

export default NewsDetail;
