import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import app_config from "../../config";
import ChatListItemCard from "./ChatListItemCard";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ChatList({ changeChat, selectedChat }) {
  const [contacts, setContacts] = useState([]);
  const [localSelectedChat, setLocalSelectedChat] = useState(null); // State to manage local selected chat

  // Determine user type based on sessionStorage data
  const userType = sessionStorage.getItem("investor") ? "investor" : "startup";

  useEffect(() => {
    const getUserById = async () => {
      let currentUser =
        JSON.parse(sessionStorage.getItem("investor")) ||
        JSON.parse(sessionStorage.getItem("startup"));

      const response = await fetch(
        `${app_config.apiurl}/chat/getchats/${currentUser._id}`,
      );
      const data = await response.json();
      let updatedContacts = data.contacts;

      // Check if the newChat is already in the contacts
      if (
        selectedChat &&
        !updatedContacts.some((contact) => contact._id === selectedChat._id)
      ) {
        updatedContacts = [...updatedContacts, selectedChat];
      }

      setContacts(updatedContacts);
    };
    getUserById();
  }, [selectedChat]);

  const changeCurrentChat = (index, chat) => {
    console.log(index);
    console.log(chat);
    setLocalSelectedChat(chat); // Update the local selected chat state
    changeChat(chat);
  };

  return (
    <>
      <ul className="overflow-auto h-[66vh] md:h-[61vh] scrollbar-custom">
        <h2 className="my-2 mb-2 ml-2 text-gray-900 dark:text-white">Chats</h2>
        {contacts.length > 0 ? (
          contacts.map((contact, index) => (
            <div
              key={contact._id}
              style={{ borderColor: "rgb(229 231 235)" }}
              className={classNames(
                contact._id === (localSelectedChat?._id || selectedChat?._id)
                  ? "bg-chatbackground dark:bg-gray-700"
                  : "transition duration-150 ease-in-out cursor-pointer bg-white border-b border-gray-200 hover:bg-gray-100 dark:bg-gray-900 dark:border-gray-700 dark:hover:bg-gray-700",
                "flex items-center px-3 py-2 text-sm",
              )}
              onClick={() => changeCurrentChat(index, contact)}
            >
              <ChatListItemCard user={contact} />
            </div>
          ))
        ) : (
          <div className="text-center text-gray-500 dark:text-gray-400 my-4">
            No contacts available.<br></br>
            <Link
              to={
                userType === "investor"
                  ? "/investor/startuplist"
                  : "/startup/investorlist"
              }
              className="text-blue-500 underline"
            >
              Create your network
            </Link>
          </div>
        )}
      </ul>
    </>
  );
}
