import React from "react";
import Header from "./Header";
import NewsDetail from "./NewsDetail";

const NewsEventDetail = () => {
  return (
    <>
      <Header />
      <NewsDetail />
    </>
  );
};

export default NewsEventDetail;
