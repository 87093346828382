import React from "react";

const Testimonial = () => {
  return (
    <div>
      <h1 className="text-center font-semibold text-black text-4xl py-3">
        Testimonials
      </h1>

      {/* Carousel wrapper */}
      <div
        id="carouselMultiItemExample"
        className="carousel slide carousel-dark text-center"
        data-mdb-ride="carousel"
      >
        {/* Inner */}
        <div className="carousel-inner py-4">
          {/* Single item */}
          <div className="carousel-item active">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 p-5">
                  <img
                    className="rounded-circle shadow-1-strong mb-4 inline-block inline-block"
                    src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp"
                    alt="avatar"
                    style={{ width: 150 }}
                  />
                  <h5 className="mb-3">Anna Deynah</h5>
                  <p></p>
                  <p className="text-muted">
                    <i className="fas fa-quote-left pe-2" />
                    I've been using this platform for my startup fundraising and
                    it has been a game-changer. The process is smooth and
                    efficient, and I've been able to connect with investors who
                    have been instrumental in taking my business to the next
                    level.
                  </p>
                  <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 d-none d-lg-block p-5">
                  <img
                    className="rounded-circle shadow-1-strong mb-4 inline-block inline-block"
                    src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(32).webp"
                    alt="avatar"
                    style={{ width: 150 }}
                  />
                  <h5 className="mb-3">John Doe</h5>
                  <p></p>
                  <p className="text-muted">
                    <i className="fas fa-quote-left pe-2" />
                    This platform has been incredibly helpful for my business.
                    Not only have I been able to secure funding, but I've also
                    been able to connect with mentors and other professionals
                    who have been instrumental in helping me grow my business.
                  </p>
                  <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star-half-alt fa-sm" />
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 d-none d-lg-block p-5">
                  <img
                    className="rounded-circle shadow-1-strong mb-4 inline-block inline-block"
                    src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(10).webp"
                    alt="avatar"
                    style={{ width: 150 }}
                  />
                  <h5 className="mb-3">Maria Kate</h5>
                  <p></p>
                  <p className="text-muted">
                    <i className="fas fa-quote-left pe-2" />
                    The team behind this platform is fantastic. They're
                    knowledgeable, helpful, and always available to answer any
                    questions I have. I've felt supported throughout the entire
                    fundraising process and would highly recommend this platform
                    to anyone looking to grow their business.
                  </p>
                  <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="far fa-star fa-sm" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="carousel-item ">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 p-5">
                  <img
                    className="rounded-circle shadow-1-strong mb-4 inline-block"
                    src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(7).webp"
                    alt="avatar"
                    style={{ width: 150 }}
                  />
                  <h5 className="mb-3">Anna Deynah</h5>
                  <p></p>
                  <p className="text-muted">
                    <i className="fas fa-quote-left pe-2" />
                    As a startup founder, I appreciated the guidance and support
                    I received from this website . Their team was always
                    available to answer questions and provide feedback, which
                    helped me refine my pitch and secure funding. I'm grateful
                    for their help and highly recommend their platform.
                  </p>
                  <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 d-none d-lg-block p-5">
                  <img
                    className="rounded-circle shadow-1-strong mb-4 inline-block"
                    src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(3).webp"
                    alt="avatar"
                    style={{ width: 150 }}
                  />
                  <h5 className="mb-3">John Doe</h5>
                  <p></p>
                  <p className="text-muted">
                    <i className="fas fa-quote-left pe-2" />
                    As the first-time founder, I was nervous about approaching
                    investors. But the team at this platform made the process
                    easy and straemlined . I was able to secure seed funding
                    quickly and efficiently and could be happier with the
                    support I received.
                  </p>
                  <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star-half-alt fa-sm" />
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 d-none d-lg-block p-5">
                  <img
                    className="rounded-circle shadow-1-strong mb-4 inline-block"
                    src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(8).webp"
                    alt="avatar"
                    style={{ width: 150 }}
                  />
                  <h5 className="mb-3">Maria Kate</h5>
                  <p></p>
                  <p className="text-muted">
                    <i className="fas fa-quote-left pe-2" />I was impressed by
                    the quality of the startups on this platform . As an
                    investor , I was able to find promising companies in a
                    variety of industries and the team was always available to
                    help me evaluate potential investments and connect with
                    founders.
                  </p>
                  <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="far fa-star fa-sm" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="carousel-item ">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 p-5">
                  <img
                    className="rounded-circle shadow-1-strong mb-4 inline-block"
                    src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp"
                    alt="avatar"
                    style={{ width: 150 }}
                  />
                  <h5 className="mb-3">Anna Deynah</h5>
                  <p></p>
                  <p className="text-muted">
                    <i className="fas fa-quote-left pe-2" />I was hesistant to
                    join another startup platform , but I am so glad I gave this
                    one try . The team is active and engaged and i have been
                    able to connect with the investors who are genunily
                    interested in my mission .
                  </p>
                  <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 d-none d-lg-block p-5">
                  <img
                    className="rounded-circle shadow-1-strong mb-4 inline-block"
                    src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(32).webp"
                    alt="avatar"
                    style={{ width: 150 }}
                  />
                  <h5 className="mb-3">John Doe</h5>
                  <p></p>
                  <p className="text-muted">
                    <i className="fas fa-quote-left pe-2" />
                    This website is a valuable resource for anyone looking to
                    invest in startups or raise capital for their business.
                    Their platform is user-friendly, and their team is
                    knowledgeable and responsive. I've had a positive experience
                    using their platform and would recommend it to anyone in the
                    startup world.
                  </p>
                  <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star-half-alt fa-sm" />
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 d-none d-lg-block p-5">
                  <img
                    className="rounded-circle shadow-1-strong mb-4 inline-block"
                    src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(10).webp"
                    alt="avatar"
                    style={{ width: 150 }}
                  />
                  <h5 className="mb-3">Maria Kate</h5>
                  <p></p>
                  <p className="text-muted">
                    <i className="fas fa-quote-left pe-2" />I was skeptical at
                    first about using an online platform to connect with
                    investors, but this website exceeded my expectations. They
                    have a robust network of investors who are serious about
                    supporting startups, I'm grateful for their support and
                    would use their platform again in the future.
                  </p>
                  <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="fas fa-star fa-sm" />
                    </li>
                    <li>
                      <i className="far fa-star fa-sm" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Inner */}

        {/* Controls */}
        <div className="d-flex justify-content-center mb-4">
          <button
            className="carousel-control-prev position-relative"
            type="button"
            data-mdb-target="#carouselMultiItemExample"
            data-mdb-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next position-relative"
            type="button"
            data-mdb-target="#carouselMultiItemExample"
            data-mdb-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      {/* Carousel wrapper */}
    </div>
  );
};

export default Testimonial;
