import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Form, Formik } from "formik";
import { MDBFile, MDBInput, MDBTextArea } from "mdb-react-ui-kit";
import { useState } from "react";
import Swal from "sweetalert2";
import * as Yup from "yup";
import app_config from "../../config";
import { startupIndustries, startupStages } from "../../Constants";
import { countries } from "../commons/countries";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// Define the options for the interests field
const interestOptions = [
  "Government",
  "Discovery",
  "Manufacturing",
  "Marketplace",
  "Mobile",
  "Platform",
  "Consulting",
  "Engineering",
  "Others",
  "Rental",
  "Research",
];

const Schema1 = Yup.object().shape({
  name: Yup.string().min(2, "Too Short!").required("Name is required"),
  brief: Yup.string()
    .required("Description is required")
    .test(
      "wordCount",
      "Description cannot exceed 150 words",
      (value) => value && value.split(/\s+/).length <= 150,
    ),
  startupStages: Yup.string().required("Startup stage is required"),
  interests: Yup.array()
    .min(1, "At least one interest is required")
    .required("Please select at least one interest"),
});

const Schema3 = Yup.object().shape({
  email: Yup.string().required("Email is required"),
  tel: Yup.string().max(10).required("contact number is required"),
});

const Register = () => {
  const url = app_config.apiurl;
  const [currentUser, setCurrentUser] = useState(() => {
    const savedUser = JSON.parse(sessionStorage.getItem("startup"));

    return savedUser
      ? {
          ...savedUser,
          interests: Array.isArray(savedUser.interests)
            ? savedUser.interests
            : savedUser.interests
              ? savedUser.interests.split(", ")
              : [],
        }
      : {};
  });

  //const [selimage, setSelimage] = useState("");

  const [startUpImage, setStartUpImage] = useState(null);
  const [documentFile, setDocumentFile] = useState(null);

  const token = sessionStorage.getItem("token");

  const updateUser = async (values, { setSubmitting }) => {
    // Convert selected interests array to a comma-separated string
    values.interests = Array.isArray(values.interests)
      ? values.interests.join(", ")
      : values.interests;

    if (startUpImage) {
      let url = await uploadImage(startUpImage);
      values.startupimage = url;
    }
    if (documentFile) {
      let url = await uploadImage(documentFile);
      values.documents = url;
    }

    setSubmitting(true);
    const res = await fetch(
      `${app_config.apiurl}/users/update/${currentUser._id}`,
      {
        method: "PUT",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );

    console.log(res.status);

    setSubmitting(false);

    if (res.status === 200) {
      const data = await res.json();
      // Ensure new interests are formatted as an array for further use
      const updatedUser = {
        ...data.result,
        interests: data.result.interests
          ? data.result.interests.split(", ")
          : [],
      };
      setCurrentUser(updatedUser);
      sessionStorage.setItem("startup", JSON.stringify(updatedUser));
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Saved Successfully",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please fill the required details",
      });
    }
  };

  const uploadImage = async (file) => {
    const fd = new FormData();
    fd.append("myfile", file);
    const res = await fetch(url + "/util/uploadfile", {
      method: "POST",
      body: fd,
    });
    let response = await res.json();
    return res.status === 200 ? response.url : null;
  };

  const handleFileChange = (setter, e) => {
    const file = e.target.files[0];
    const maxSizeInBytes = 5 * 1024 * 1024;
    if (file && file.size > maxSizeInBytes) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "File size exceeds the maximum limit of 5MB.",
      });
    } else {
      setter(file);
    }
  };

  return (
    <>
      <div
        className="md:ml-16 md:mx-12 mx-8 mt-12"
        style={{ height: "fit-content", width: "fit-content" }}
      >
        <h1 className="text-black md:text-4xl text-3xl font-semibold sm:mt-0">
          Edit Profile
        </h1>
        {/* <!-- Tabs navs --> */}
        <ul
          className="nav nav-tabs nav-justified md:space-x-5 mt-3 md:mb-7 mb-4"
          id="ex3"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <a
              className="nav-link active"
              id="ex3-tab-1"
              data-mdb-toggle="tab"
              href="#ex3-tabs-1"
              role="tab"
              aria-controls="ex3-tabs-1"
              aria-selected="true"
            >
              Basic Info
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className="nav-link"
              id="ex3-tab-2"
              data-mdb-toggle="tab"
              href="#ex3-tabs-2"
              role="tab"
              aria-controls="ex3-tabs-2"
              aria-selected="false"
            >
              {" "}
              Team
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className="nav-link"
              id="ex3-tab-3"
              data-mdb-toggle="tab"
              href="#ex3-tabs-3"
              role="tab"
              aria-controls="ex3-tabs-3"
              aria-selected="false"
            >
              Personal details
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className="nav-link"
              id="ex3-tab-4"
              data-mdb-toggle="tab"
              href="#ex3-tabs-4"
              role="tab"
              aria-controls="ex3-tabs-4"
              aria-selected="false"
            >
              {" "}
              Others
            </a>
          </li>
        </ul>
        {/* <!-- Tabs navs --> */}

        {/* <!-- Tabs content --> */}
        <div className="tab-content" id="ex2-content">
          <div
            className="tab-pane fade show active"
            id="ex3-tabs-1"
            role="tabpanel"
            aria-labelledby="ex3-tab-1"
          >
            {/* first form  */}
            <div className="tab-content mb-5" id="v-pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="v-pills-About Startup"
                role="tabpanel"
                aria-labelledby="v-pills-About Startup-tab"
              >
                <Formik
                  initialValues={{
                    ...currentUser,
                    startupStages: currentUser.startupStages || "", // Set to an empty string if no value
                  }}
                  validationSchema={Schema1}
                  onSubmit={updateUser}
                >
                  {({
                    values,
                    handleSubmit,
                    handleChange,
                    isSubmitting,
                    errors,
                    touched,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="d-flex flex-column">
                        <div className="form-outline mb-2">
                          <div className="file-upload-wrapper">
                            <div className="image-body mt-6 md:mt-0 ml-2">
                              <label
                                htmlFor="startupimage"
                                className="text-base text-black mb-2"
                              >
                                Startup Image
                              </label>
                              <br />
                              <div className="relative flex items-center justify-center w-20 h-20 rounded-full border-2 border-gray-300 bg-gray-100 overflow-hidden cursor-pointer">
                                {startUpImage || currentUser.startupimage ? (
                                  <img
                                    src={
                                      startUpImage
                                        ? URL.createObjectURL(startUpImage)
                                        : currentUser.startupimage
                                    }
                                    alt="Startup Preview"
                                    className="w-full h-full object-cover object-top rounded-full"
                                  />
                                ) : (
                                  <img
                                    src="/AvatarPlaceholder.svg" // Path to your saved SVG file
                                    alt="Avatar Placeholder"
                                    className="w-[85%] h-[85%]"
                                  />
                                )}
                                <input
                                  type="file"
                                  name="startupimage"
                                  className="absolute inset-0 opacity-0 cursor-pointer"
                                  onChange={(e) =>
                                    handleFileChange(setStartUpImage, e)
                                  }
                                  accept="image/*"
                                />
                              </div>

                              {/* Dynamic text for upload and re-upload */}
                              <p className="mt-2 text-sm font-medium text-gray-500">
                                {startUpImage || currentUser.startupimage
                                  ? "*Click to Re-upload Image"
                                  : "*Click to Upload Image"}
                              </p>

                              {/* Display error message if there's any */}
                              {errors.startupimage && touched.startupimage ? (
                                <div className="text-deny">
                                  {errors.startupimage}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="form-outline mt-4 ms-1">
                          <MDBInput
                            label="Startup Name"
                            id="name"
                            type="text"
                            value={values.name}
                            onChange={handleChange}
                            name="name"
                          />
                          {errors.name && touched.name ? (
                            <div className="text-deny">{errors.name}</div>
                          ) : null}
                        </div>
                        <div className="form-outline mt-4 ms-1">
                          <MDBInput
                            label="Created At"
                            id="created_at"
                            type="date"
                            value={
                              values.created_at
                                ? values.created_at.substring(0, 10)
                                : ""
                            }
                            onChange={handleChange}
                            name="created_at"
                          />
                        </div>

                        <FormControl className="form-outline mt-4 ms-1">
                          <FormLabel id="demo-radio-buttons-group-label">
                            Funded Or Bootstrapped
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="none"
                            name="type"
                            id="type"
                            value={values.type}
                            onChange={handleChange}
                          >
                            <div className="form-outline ">
                              <FormControlLabel
                                value="Funded"
                                control={<Radio />}
                                label="Funded"
                              />
                              <FormControlLabel
                                value="Bootstrapped"
                                control={<Radio />}
                                label="Bootstrapped"
                              />
                            </div>
                          </RadioGroup>
                        </FormControl>
                        <div className="form-outline mt-4 ms-1">
                          <MDBInput
                            label="Current Raise Amount"
                            id="currentRaiseAmount"
                            type="number"
                            value={values.currentRaiseAmount}
                            onChange={handleChange}
                            name="currentRaiseAmount"
                          />
                        </div>
                        <FormControl className="form-outline mt-4 ms-1">
                          <FormLabel id="startup-stages-label">
                            Startup Stage
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="startup-stages-label"
                            name="startupStages"
                            value={values.startupStages}
                            onChange={(e) =>
                              setFieldValue("startupStages", e.target.value)
                            }
                          >
                            {startupStages.map((stage) => (
                              <FormControlLabel
                                key={stage.value}
                                value={stage.value}
                                control={<Radio />}
                                label={stage.text}
                              />
                            ))}
                          </RadioGroup>
                          {errors.startupStages && touched.startupStages ? (
                            <div className="text-deny">
                              {errors.startupStages}
                            </div>
                          ) : null}
                        </FormControl>

                        <div className="form-outline mt-4 ms-1">
                          <MDBTextArea
                            label="Brief"
                            type="text"
                            id="brief"
                            rows={2}
                            value={values.brief}
                            onChange={handleChange}
                            name="brief"
                          />
                          {errors.brief && touched.brief ? (
                            <div className="text-deny">{errors.brief}</div>
                          ) : null}
                          <div>
                            Word Count:{" "}
                            {values.brief
                              ? values.brief.split(/\s+/).length
                              : 0}
                            /150
                          </div>
                        </div>
                      </div>

                      <div className="form-outline mb-2">
                        <div className="file-upload-wrapper">
                          <div className="image-body">
                            <MDBFile
                              label="Documents"
                              type="file"
                              id="sdocuments"
                              name="sdocuments"
                              value={values.sdocuments}
                              onChange={(e) =>
                                handleFileChange(setDocumentFile, e)
                              }
                            />
                            {/* {selimage === '' && currentUser.sdocuments === '' ? (
                              <div>{'Image Required'}</div>
                            ) : null} */}
                          </div>
                        </div>
                      </div>

                      <button
                        disabled={isSubmitting}
                        type="submit"
                        className="btn btn-block mb-1"
                        style={{ backgroundColor: "#b0ff4b", color: "black" }}
                      >
                        {isSubmitting ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Save"
                        )}
                      </button>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
            {/* first form end  */}
          </div>

          <div
            className="tab-pane fade"
            id="ex3-tabs-2"
            role="tabpanel"
            aria-labelledby="ex3-tab-2"
          >
            {/* second form */}
            <div className="tab-content mb-5" id="v-pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="v-pills-Owner And Product Details"
                role="tabpanel"
                aria-labelledby="v-pills-Owner And Product Details-tab"
              >
                <Formik
                  initialValues={currentUser}
                  // validationSchema={Schema2}
                  onSubmit={updateUser}
                >
                  {({
                    values,
                    handleSubmit,
                    handleChange,
                    isSubmitting,
                    errors,
                    touched,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <div>
                        <div className="form-outline mb-4">
                          <MDBInput
                            label="Owner Name"
                            id="ownername"
                            type="text"
                            value={values.ownername}
                            onChange={handleChange}
                            name="ownername"
                          />
                          {errors.ownername && touched.ownername ? (
                            <div>{errors.ownername}</div>
                          ) : null}
                        </div>
                      </div>
                      <div>
                        {/* <div class="form-outline mb-4">
                        <div class="file-upload-wrapper">
                          <div class="image-body">
                            <MDBFile label='Identity Proof(Aadhar Card/PAN/VoterID)'type="file" id='identityproof' name="identityproof" value={values.identityproof} onChange={uploadImage} /></div>
                            {selimage === '' && currentUser.identityproof ==='' ? (
                          <div>{'Image Required'}</div>
                        ) : null}</div>
                        </div> */}

                        {/* <div class="form-outline mb-4">
                        <div class="file-upload-wrapper">
                          <div class="image-body">

                            <MDBFile label='Document'type="file" id='documents' name="documents" value={values.documents} onChange={uploadImage} /></div>
                            {selimage === '' && currentUser.documents ==='' ? (
                          <div>{'Image Required'}</div>
                        ) : null}
                       
                          </div>
                        </div> */}

                        <div className="col">
                          <div className="form-outline mb-4">
                            <MDBInput
                              label="Contact"
                              type="text"
                              id="ownercontact"
                              name="ownercontact"
                              value={values.ownercontact}
                              onChange={handleChange}
                              maxLength={10}
                              minLength={10}
                            />
                          </div>
                        </div>

                        <div className="form-outline mb-4">
                          <MDBInput
                            label="No of members in team "
                            type="number"
                            id="teammemberno"
                            value={values.teammemberno}
                            onChange={handleChange}
                            name="teammemberno"
                          />
                        </div>
                        <div className="form-outline mb-4">
                          <MDBTextArea
                            label="Members Of Team"
                            type="text"
                            id="teammembers"
                            rows={2}
                            value={values.teammembers}
                            onChange={handleChange}
                            name="teammembers"
                          />
                        </div>
                      </div>

                      <button
                        disabled={isSubmitting}
                        type="submit"
                        className="btn btn-block mb-1"
                        style={{ backgroundColor: "#b0ff4b", color: "black" }}
                      >
                        {isSubmitting ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Save"
                        )}
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            {/* second form end  */}
          </div>

          <div
            className="tab-pane fade"
            id="ex3-tabs-3"
            role="tabpanel"
            aria-labelledby="ex3-tab-3"
          >
            {/* third form  */}
            <div className="tab-content mb-5" id="v-pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="v-pills-Owner And Product Details"
                role="tabpanel"
                aria-labelledby="v-pills-Owner And Product Details-tab"
              >
                <Formik
                  initialValues={currentUser}
                  validationSchema={Schema3}
                  onSubmit={updateUser}
                >
                  {({
                    values,
                    handleSubmit,
                    handleChange,
                    isSubmitting,
                    errors,
                    touched,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="row mb-4 form-floating ">
                        <div className="col">
                          <div className="form-outline mb-4">
                            <MDBInput
                              label="Email Address"
                              type="text"
                              value={values.email}
                              onChange={handleChange}
                              name="email"
                            />
                            {errors.email && touched.brief ? (
                              <div>{errors.email}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-outline mb-4">
                            <MDBInput
                              label=" Mobile"
                              type="text"
                              id="tel"
                              value={values.tel}
                              onChange={handleChange}
                              name="tel"
                              maxLength={10}
                              minLength={10}
                            />
                            {errors.tel ? <div>{errors.tel}</div> : null}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4 form-floating">
                        <div className="col">
                          <div className="form-outline mb-4">
                            <MDBInput
                              label=" State"
                              type="text"
                              id="state"
                              value={values.state}
                              onChange={handleChange}
                              name="state"
                            />
                          </div>
                          <div className="col">
                            <div className="form-outline mb-4">
                              <MDBInput
                                label="City "
                                type="text"
                                id="City"
                                value={values.city}
                                onChange={handleChange}
                                name="city"
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-outline mb-4">
                              <select
                                id="location"
                                onChange={handleChange}
                                value={values.location}
                                className="bg-gray-50 border border-black-900 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              >
                                <option value="">Location</option>
                                {countries.map((option) => {
                                  return (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-4 form-floating">
                        <div className="col">
                          <div className="form-outline mb-4">
                            <MDBInput
                              label="Website "
                              type="text"
                              id="website"
                              value={values.website}
                              onChange={handleChange}
                              name="website"
                            />
                          </div>
                          <div className="col">
                            <div className="form-outline mb-4">
                              <MDBInput
                                label="Mobile App Link "
                                type="text"
                                id="app"
                                value={values.app}
                                onChange={handleChange}
                                name="app"
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-outline mb-4">
                              <MDBInput
                                label="LinkedIn Profile"
                                type="text"
                                id="linkedInUrl"
                                value={values.linkedInUrl}
                                onChange={handleChange}
                                name="linkedInUrl"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <button
                        disabled={isSubmitting}
                        type="submit"
                        className="btn btn-block mb-1"
                        style={{ backgroundColor: "#b0ff4b", color: "black" }}
                      >
                        {isSubmitting ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Save"
                        )}
                      </button>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
            {/* third form end  */}
          </div>

          {/*fourth form */}
          <div className="tab-pane fade" id="ex3-tabs-4" role="tabpanel">
            <Formik
              initialValues={currentUser}
              validationSchema={Schema1}
              onSubmit={updateUser}
            >
              {({
                values,
                handleSubmit,
                handleChange,
                setFieldValue,
                isSubmitting,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="col-12 mb-4">
                    <label htmlFor="select1">Your Industry</label>
                    <br />
                    <select
                      className="select border border-gray-300 rounded-md px-2 py-2 focus:outline-none focus:border-blue-500"
                      name="industry"
                      value={values.industry}
                      onChange={handleChange}
                    >
                      <option defaultChecked="true">Select Industry</option>
                      {startupIndustries.map((ind) => (
                        <option key={ind.value} value={ind.value}>
                          {ind.text}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-12 mb-4">
                    <label htmlFor="interests" className="mb-2">
                      Interests
                    </label>
                    <Autocomplete
                      multiple
                      limitTags={1}
                      id="interests"
                      options={interestOptions}
                      disableCloseOnSelect
                      value={
                        Array.isArray(values.interests)
                          ? values.interests
                          : values.interests
                            ? values.interests.split(", ")
                            : ""
                      }
                      onChange={(event, newValue) => {
                        setFieldValue("interests", newValue);
                      }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Select Interests"
                          placeholder="Select Interests"
                          size="small" // Reduce the size of the text field
                          sx={{
                            width: 305, // Adjust width as desired

                            "& .MuiInputLabel-root": {
                              fontSize: "0.875rem", // Adjust label font size
                            },
                          }}
                        />
                      )}
                    />
                    {touched.interests && errors.interests && (
                      <div className="text-deny mt-1">{errors.interests}</div> // Custom error message
                    )}
                  </div>

                  <button
                    disabled={isSubmitting}
                    type="submit"
                    className="btn btn-block mb-1"
                    style={{ backgroundColor: "#b0ff4b", color: "black" }}
                  >
                    {isSubmitting ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      "Save"
                    )}
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
