import React from "react";
import AlphabetAvatar from "../AlphabetAvatar";

export default function ChatListItemCard({ user }) {
  let photoURL =
    user.role === "investor" ? user.investoravatar : user.startupimage;
  let displayName =
    user.role === "investor" ? `${user.firstName} ${user.lastName}` : user.name;

  return (
    <div
      className="relative flex items-center mb-2"
      style={{ borderColor: "rgb(107 114 128)" }}
    >
      {photoURL ? (
        <img className="w-10 h-10 rounded-full" src={photoURL} alt="" />
      ) : (
        <AlphabetAvatar
          className="!w-10 !h-10"
          alphabet={displayName ? displayName.charAt(0) : "?"}
        />
      )}
      <span className="block ml-2 text-gray-500 dark:text-gray-400">
        {displayName}
      </span>

      {/* <span className="bottom-0 left-7 absolute  w-3.5 h-3.5 bg-gray-400 border-2 border-white rounded-full"></span> */}
    </div>
  );
}
