import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
import Header from "./Header";
import "./Scout.css";
import ObjectiveDetail from "./ObjectiveDetail";
import Testimonial from "./Testimonial";
import ForScoutForm from "./ForScoutForm";

const Scout = () => {
  return (
    <>
      {/* <Header /> */}
      <Header />
      <AnimatePresence>
        <motion.div
          className="row homerow pt-5 md:!h-[60vh]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <motion.div
            className="col-md-4 p-4 "
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h1 className="text-4xl text-black font-semibold py-1 md:ml-0 ml-8">
              Help founders raise funding using your investor network
            </h1>
            <ul class="py-1 text-black font-medium md:ml-0 ml-8">
              <li class="flex items-center">
                <span class="mr-2">&#8226;</span> Grow your dealflow
              </li>
              <li class="flex items-center">
                <span class="mr-2">&#8226;</span> Find unique startups
              </li>
              <li class="flex items-center">
                <span class="mr-2">&#8226;</span> Network with founders
              </li>
            </ul>
          </motion.div>

          <motion.div
            className="col-md-4 heroimg"
            initial={{ x: 50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <img className="hero2-image w-[75%]" src="\scout_home.png" alt="" />
          </motion.div>
        </motion.div>
      </AnimatePresence>

      <div
        className="row mt-5 p-20 justify-content-center align-items-center"
        style={{ backgroundColor: "white" }}
      >
        <div className="col-md-4 mb-3 -ml-12 sm:ml-0">
          <img
            src="/objective2.png"
            alt=""
            style={{ width: "125%", maxWidth: "125%" }}
          />
        </div>
        <div className="col-md-6">
          <p className="py-2 text-black text-xl sm:pl-44 -ml-8 sm:ml-0">
            <p className="font-semibold text-4xl">What is a kreedify pro?</p>
            <br></br>A kreedify scout is someone who has access to startup{" "}
            <br className="hidden sm:block"></br>
            investors & is willing to join the platform to help startups.{" "}
            <br className="hidden sm:block"></br>
            It could be your main job or just a way to help founders{" "}
            <br className="hidden sm:block"></br>
            raise funding!<br></br>
          </p>
        </div>
      </div>

      <div className="row p-5 justify-content-center align-items-center">
        <div className="col-md-6">
          <div className="col-md-4 sm:hidden">
            <img
              src="/need.png"
              alt=""
              className="h-64 w-58 mx-auto sm:ml-16"
            />
          </div>
          <p className="py-2 text-black text-xl ml-0 sm:ml-20">
            <p className="font-semibold text-4xl">
              Join kreedify as a Investor Scout!
            </p>
            <br className="block"></br>
            You might be a VC scout, working professional, GP or{" "}
            <br className="hidden sm:block"></br>
            fund manager. This platform is for you to help founders
            <br className="hidden sm:block"></br>
            connect with the investor network you have<br></br>
          </p>
        </div>
        <div className="col-md-4 hidden sm:block">
          <img src="/need.png" alt="" className="w-[60%]" />
        </div>
      </div>

      <Testimonial />
      <ForScoutForm />
    </>
  );
};

export default Scout;

//startup list details add
//profile page
//detail id se
