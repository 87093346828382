import { Button } from "@mui/base";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import app_config from "../../config";

const NewsBrowser = () => {
  const [newsList, setNewsList] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");

  const navigate = useNavigate();

  const search = (field) => {
    setNewsList((prevNewsList) =>
      prevNewsList.filter((news) =>
        news[field]?.toLowerCase().includes(searchKeyword.toLowerCase()),
      ),
    );
  };

  const fetchData = async () => {
    try {
      const response = await fetch(`${app_config.apiurl}/News/news-events`);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("API Response:", data.result);

      if (data && data.result && Array.isArray(data.result)) {
        const formattedNewsList = data.result.map((newsItem) => ({
          id: newsItem._id,
          heading: newsItem.title.trim(),
          description: newsItem.descriptiom,
          image: newsItem.image,
          link: newsItem.link,
        }));

        setNewsList(formattedNewsList);
      } else {
        throw new Error("News data is not in the expected format");
      }
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="mb-4">
      <div
        className="flex flex-col sm:flex-row align-items-center justify-content-center mx-6 mt-[2rem] md:mt-3 z-[0]"
        style={{ height: "25vh", backgroundColor: "#fff" }}
      >
        <div className=" mt-4">
          <h1 className="text-center text-3xl md:text-5xl font-semibold text-black">
            News
          </h1>
        </div>
      </div>

      <div className="flex mt-3" style={{ justifyContent: "space-between" }}>
        <div
          className="col-6 d-flex align-items-center ml-6 md:ml-10"
          style={{ height: "40px" }}
        >
          <label
            style={{ color: "black" }}
            className="form-label m-2"
            htmlFor="category"
          >
            Sort
          </label>
          &nbsp;
          <select
            className="form-control"
            name="category"
            id="category"
            style={{ maxWidth: "50%" }}
            value={selectedCategory}
            onChange={(e) => {
              setSelectedCategory(e.target.value);
              search("heading");
            }}
          >
            <option value="all" selected>
              All
            </option>
            <option value="technology">Technology</option>
            <option value="Marketing">Marketing</option>
            <option value="Sales">Sales</option>
            <option value="Shares">Shares</option>
            <option value="Consulting">Consulting</option>
          </select>
        </div>

        <div
          className="col-6 input-group mr-10 w-[40%] md:w-[30%]"
          style={{ height: "36px" }}
        >
          <div className="form-outline bg-white">
            <input
              type="search"
              id="form1"
              className="form-control rounded"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
            <label className="form-label rounded" htmlFor="form1">
              Search
            </label>
          </div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => search("heading")}
          >
            <i className="fas fa-search" />
          </button>
        </div>
      </div>

      <div className="px-4 mt-4">
        <div className="flex flex-col gap-4">
          {newsList.map((news) => (
            <div className="bg-white p-4" key={news.id}>
              <div className="flex flex-col  md:flex-row gap-4">
                <div className="">
                  <img
                    src={news.image}
                    className="min-w-[180px] md:min-w-[300px] max-w-xs w-full"
                    alt={news.heading}
                  />
                </div>
                <div className="flex flex-col gap-3 max-w-xs md:max-w-full">
                  <div>
                    <span className="badge bg-primary px-2  shadow-1-strong ">
                      News of the day
                    </span>
                  </div>
                  <h4>
                    <strong>{news.heading}</strong>
                  </h4>
                  <p className="text-muted overflow-hidden truncate max-w-md">
                    {news.description}
                  </p>
                  <div>
                    <Button
                      type="button"
                      className="btn"
                      onClick={() => navigate(news.id)}
                      style={{ backgroundColor: "#b0ff4b", color: "black" }}
                    >
                      Read more
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewsBrowser;
