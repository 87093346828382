import React from "react";
import { useNavigate } from "react-router-dom";
import planData from "../../planData";
import "./Subscription.css";

const Subscription = () => {
  const navigate = useNavigate();

  return (
    <div>
      <section className="sec">
        <center>
          <div className="pricing pricing-palden">
            <div
              className="pricing-item features-item ja-animate"
              data-animation="move-from-bottom"
              data-delay="item-0"
              style={{ minHeight: "497px" }}
            >
              <div className="pricing-deco">
                <svg
                  className="pricing-deco-img"
                  enableBackground="new 0 0 300 100"
                  height="100px"
                  id="Layer_1"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 300 100"
                  width="300px"
                  x="0px"
                  xmlSpace="preserve"
                  y="0px"
                >
                  <path
                    className="deco-layer deco-layer--1"
                    d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z"
                    fill="#FFFFFF"
                    opacity="0.6"
                  />
                  <path
                    className="deco-layer deco-layer--2"
                    d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z"
                    fill="#FFFFFF"
                    opacity="0.6"
                  />
                  <path
                    className="deco-layer deco-layer--3"
                    d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716H42.401L43.415,98.342z"
                    fill="#FFFFFF"
                    opacity="0.7"
                  />
                  <path
                    className="deco-layer deco-layer--4"
                    d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z"
                    fill="#FFFFFF"
                  />
                </svg>
                <div className="pricing-price">
                  <span className="pricing-currency">₹</span>29
                  <span className="pricing-period">/ month</span>
                </div>
                <h3 className="pricing-title">
                  <strong>Basic</strong>
                </h3>
              </div>
              <ul className="pricing-feature-list" style={{}}>
                <li className="pricing-feature">Consultancy</li>
                <li className="pricing-feature"></li>
                <li className="pricing-feature">Explore Startups</li>
                <li className="pricing-feature"></li>
                <li className="pricing-feature">Customer Support</li>
              </ul>
              <button
                className="pricing-action"
                onClick={(e) => {
                  localStorage.setItem(
                    "planDetails",
                    JSON.stringify(planData.basic),
                  );
                  window.location.replace(
                    "https://buy.stripe.com/test_6oEg2abIo91U5uEeV1",
                  );
                }}
              >
                Choose plan
              </button>
            </div>
            <div
              className="pricing-item features-item ja-animate pricing__item--featured"
              data-animation="move-from-bottom"
              data-delay="item-1"
              style={{ minHeight: "497px" }}
            >
              <div
                className="pricing-deco"
                style={{
                  background: "linear-gradient(135deg,#2ed60d,#147800)",
                }}
              >
                <svg
                  className="pricing-deco-img"
                  enableBackground="new 0 0 300 100"
                  height="100px"
                  id="Layer_1"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 300 100"
                  width="300px"
                  x="0px"
                  xmlSpace="preserve"
                  y="0px"
                >
                  <path
                    className="deco-layer deco-layer--1"
                    d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z"
                    fill="#FFFFFF"
                    opacity="0.6"
                  />
                  <path
                    className="deco-layer deco-layer--2"
                    d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z"
                    fill="#FFFFFF"
                    opacity="0.6"
                  />
                  <path
                    className="deco-layer deco-layer--3"
                    d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716H42.401L43.415,98.342z"
                    fill="#FFFFFF"
                    opacity="0.7"
                  />
                  <path
                    className="deco-layer deco-layer--4"
                    d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z"
                    fill="#FFFFFF"
                  />
                </svg>
                <div className="pricing-price">
                  <span className="pricing-currency">₹</span>59
                  <span className="pricing-period">/ month</span>
                </div>
                <h3 className="pricing-title">
                  <strong>Standard</strong>
                </h3>
              </div>
              <ul className="pricing-feature-list">
                <li className="pricing-feature">Chatting</li>
                <li className="pricing-feature"></li>
                <li className="pricing-feature">Consultancy</li>
                <li className="pricing-feature"></li>
                <li className="pricing-feature">Explore Startups</li>
              </ul>
              <button
                className="pricing-action"
                onClick={(e) => {
                  localStorage.setItem(
                    "planDetails",
                    JSON.stringify(planData.regular),
                  );
                  window.location.replace(
                    "https://buy.stripe.com/test_bIYaHQ8wcba28GQcMP",
                  );
                }}
              >
                Choose plan
              </button>
            </div>
            <div
              className="pricing-item features-item ja-animate"
              data-animation="move-from-bottom"
              data-delay="item-2"
              style={{ minHeight: "497px" }}
            >
              <div className="pricing-deco">
                <svg
                  className="pricing-deco-img"
                  enableBackground="new 0 0 300 100"
                  height="100px"
                  id="Layer_1"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 300 100"
                  width="300px"
                  x="0px"
                  xmlSpace="preserve"
                  y="0px"
                >
                  <path
                    className="deco-layer deco-layer--1"
                    d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z"
                    fill="#FFFFFF"
                    opacity="0.6"
                  />
                  <path
                    className="deco-layer deco-layer--2"
                    d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z"
                    fill="#FFFFFF"
                    opacity="0.6"
                  />
                  <path
                    className="deco-layer deco-layer--3"
                    d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716H42.401L43.415,98.342z"
                    fill="#FFFFFF"
                    opacity="0.7"
                  />
                  <path
                    className="deco-layer deco-layer--4"
                    d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z"
                    fill="#FFFFFF"
                  />
                </svg>
                <div className="pricing-price">
                  <span className="pricing-currency">₹</span>99
                  <span className="pricing-period">/ month</span>
                </div>
                <h3 className="pricing-title">
                  <strong>Premium</strong>
                </h3>
              </div>
              <ul className="pricing-feature-list">
                <li className="pricing-feature">Chatting</li>
                <li className="pricing-feature"></li>
                <li className="pricing-feature">Consultancy</li>
                <li className="pricing-feature"></li>

                <li className="pricing-feature">Campaigns And Drives</li>
              </ul>
              <button
                className="pricing-action"
                onClick={(e) => {
                  localStorage.setItem(
                    "planDetails",
                    JSON.stringify(planData.premium),
                  );
                  window.location.replace(
                    "https://buy.stripe.com/test_eVa2bkfYE7XQ1eofZ3",
                  );
                }}
              >
                Choose plan
              </button>
            </div>
          </div>
        </center>
      </section>
    </div>
  );
};

export default Subscription;
