import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import app_config from "../../config";
import {
  fetchStarupStageLabel,
  startupIndustriesLookup,
} from "../../Constants";
import AlphabetAvatar from "../AlphabetAvatar";
import { findCountryLabel } from "../commons/countries";

const StartupDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [startupData, setStartupData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchStartupById = async () => {
      setLoading(true);
      const res = await fetch(`${app_config.apiurl}/users/getbyid/${id}`);
      const data = await res.json();
      console.log(data);
      setStartupData(data.result);
      setLoading(false);
    };

    fetchStartupById();
  }, [id]);

  const openChat = (startupData) => {
    navigate("/investor/inbox", { state: { selectedUser: startupData } });
  };

  const isAvatarImageAvaiable = (imageUrl) => {
    return (
      imageUrl !== undefined &&
      imageUrl !== null &&
      imageUrl !== "undefined" &&
      imageUrl.trim().length > 0
    );
  };

  const displayDetails = () => {
    if (!loading && startupData) {
      return (
        <>
          <div>
            <div className=" h-[12rem] flex md:justify-start justify-center">
              <h1 className="text-black font-bold text-[2.5rem] pt-4 md:ml-20 mt-8">
                Details
              </h1>
            </div>
            <div className="sm:px-12 mt-[-4rem] max-w-6xl flex flex-col sm:flex-row ">
              <div className="sm:w-[37%] mb-4 sm:mb-0">
                <div className="flex flex-col gap-4 items-center py-6 sm:p-6  bg-[#fff] mx-4 rounded-xl shadow-xl">
                  <div className=" ">
                    {isAvatarImageAvaiable(startupData.startupimage) ? (
                      <img
                        className="w-32 h-32 rounded-full"
                        src={startupData.startupimage}
                        alt="avatar"
                      />
                    ) : (
                      <AlphabetAvatar alphabet={startupData.name.charAt(0)} />
                    )}
                  </div>
                  <div className=" flex flex-col gap-2 items-center ">
                    <h2 className="text-2xl  font-medium">
                      {startupData?.name}
                    </h2>
                    {startupData?.brief && (
                      <p className="text-center">
                        <i className="fas fa-quote-left"></i>
                        {startupData?.brief}
                      </p>
                    )}
                  </div>
                  <div>
                    <button
                      className="p-2 rounded-lg"
                      style={{
                        backgroundColor: "#b0ff4b",
                        color: "black",
                        width: "fit-content",
                      }}
                      onClick={() => openChat(startupData)}
                    >
                      Start Chatting
                    </button>
                  </div>
                </div>
              </div>
              <div className="sm:w-[63%] px-6  bg-[#fff] shadow-xl rounded-xl mb-4  mx-4 ">
                {/* Basic information */}
                <div>
                  <div className="py-2 border-b border-[#d3cdcd]">
                    <h2 className="font-medium text-[19px]">Address</h2>
                  </div>
                  <div className="flex flex-col sm:flex-row">
                    <div className="sm:w-[55%]">
                      <div className="bg-[#fff] rounded-xl p-3">
                        <div className=" flex flex-col gap-2 ">
                          <p className="text-[#a3a0a0]">Website:</p>
                          <p className="font-medium">
                            {startupData.website || "Not Available"}
                          </p>
                        </div>
                      </div>

                      <div className="bg-[#fff] rounded-xl p-3 ">
                        <div className=" flex flex-col gap-2 ">
                          <p className="text-[#a3a0a0]">City</p>
                          <p className="font-medium">
                            {startupData.city || "Not Available"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="sm:w-[45%]">
                      <div className="bg-[#fff] rounded-xl p-3 ">
                        <div className=" flex flex-col gap-2 ">
                          <p className="text-[#a3a0a0]">State:</p>
                          <p className="font-medium">
                            {startupData.state || "Not Available"}
                          </p>
                        </div>
                      </div>

                      <div className="bg-[#fff] rounded-xl p-3 ">
                        <div className=" flex flex-col gap-2 ">
                          <p className="text-[#a3a0a0]">Location:</p>
                          <p className="font-medium">
                            {findCountryLabel(startupData.location) ||
                              "Not Available"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="py-2 border-b border-[#d3cdcd]">
                    <h2 className="font-medium text-[19px]">
                      Startup Information
                    </h2>
                  </div>
                  <div className="flex flex-col sm:flex-row">
                    <div className="sm:w-[55%]">
                      <div className="bg-[#fff] rounded-xl p-3 ">
                        <div className=" flex flex-col gap-2 ">
                          <p className="text-[#a3a0a0]">Startup Type:</p>
                          <p className="font-medium">
                            {startupData.type || "Not Available"}
                          </p>
                        </div>
                      </div>

                      <div className="bg-[#fff] rounded-xl p-3 ">
                        <div className=" flex flex-col gap-2 ">
                          <p className="text-[#a3a0a0]">Startup stages:</p>
                          <p className="font-medium">
                            {fetchStarupStageLabel(startupData.startupStages) ||
                              "Not Available"}
                          </p>
                        </div>
                      </div>
                      <div className="bg-[#fff] rounded-xl p-3 ">
                        <div className=" flex flex-col gap-2 ">
                          <p className="text-[#a3a0a0]">Interests:</p>
                          <p className="font-medium">
                            {startupData.interests || "Not Available"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="sm:w-[45%]">
                      <div className="bg-[#fff] rounded-xl p-3 ">
                        <div className=" flex flex-col gap-2 ">
                          <p className="text-[#a3a0a0]">App name:</p>
                          <p className="font-medium">
                            {startupData.productname || "Not Available"}
                          </p>
                        </div>
                      </div>

                      <div className="bg-[#fff] rounded-xl p-3 ">
                        <div className=" flex flex-col gap-2 ">
                          <p className="text-[#a3a0a0]">
                            Current Raised Amount:
                          </p>
                          <p className="font-medium">
                            {startupData.currentRaiseAmount || "Not Available"}
                          </p>
                        </div>
                      </div>

                      <div className="bg-[#fff] rounded-xl p-3 ">
                        <div className=" flex flex-col gap-2 ">
                          <p className="text-[#a3a0a0]">Industry:</p>
                          <p className="font-medium">
                            {startupIndustriesLookup[startupData.industry] ||
                              "Not Available"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div className="text-center mt-64">
          {/* <h1 style={{ color: "#518f3d" }}>Loading...</h1> */}
          <CircularProgress color="success" />
        </div>
      );
    }
  };

  return displayDetails();
};

export default StartupDetails;
