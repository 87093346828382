import { format } from "timeago.js";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Message({ message, self }) {
  const isSelf = self === message.sentBy;

  return (
    <li
      className={classNames(isSelf ? "justify-end" : "justify-start", "flex")}
    >
      <div
        className={classNames(
          isSelf ? "ml-12 md:ml-28" : "mr-12 md:mr-28", // Apply margin-left for sending messages and margin-right for receiving messages
          "relative max-w-xl",
        )}
      >
        <div
          className={classNames(
            isSelf
              ? "bg-accept text-white" // Apply bg-accept for the current user's messages
              : "text-gray-700 dark:text-gray-400 bg-white border border-gray-200 shadow-md dark:bg-gray-900 dark:border-gray-700",
            "px-4 py-2 rounded-lg shadow",
          )}
        >
          <span className="block font-normal">{message.message}</span>
        </div>
        <span className="block text-sm text-gray-700 dark:text-gray-400">
          {format(message.date)}
        </span>
      </div>
    </li>
  );
}
