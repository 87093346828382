import React from "react";
import {
  Autocomplete,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

// Define the icon for checkbox states
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Step3InterestsFunding = ({
  values,
  handleChange,
  errors,
  touched,
  setFieldValue,
}) => {
  // List of available interest options
  const interestOptions = [
    "Government",
    "Discovery",
    "Manufacturing",
    "Marketplace",
    "Mobile",
    "Platform",
    "Consulting",
    "Engineering",
    "Others",
    "Rental",
    "Research",
  ];

  return (
    <>
      <div className="mb-4 md:!mb-6">
        <label
          htmlFor="interests"
          className="text-lg font-semibold text-black mb-2"
        >
          Interests
        </label>
        <Autocomplete
          multiple
          limitTags={1} // Limit the number of tags shown
          id="interests"
          options={interestOptions}
          disableCloseOnSelect // Keeps the dropdown open after selecting an item
          value={values.interests || []} // Bind the current selected values or an empty array
          onChange={(event, newValue) => {
            setFieldValue("interests", newValue); // Update Formik values with selected interests
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected} // Mark the checkbox as checked if the option is selected
              />
              {option}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Select Interests"
              placeholder="Select Interests"
            />
          )}
          isOptionEqualToValue={(option, value) => option === value} // Ensure matching values for comparison
        />
      </div>

      {/* Funding Type Radio Buttons */}
      <FormControl className="form-outline">
        <label htmlFor="type" className="text-lg font-semibold text-black">
          Funded or Bootstrapped
        </label>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="type"
          id="type"
          value={values.type}
          onChange={handleChange}
        >
          <div className="form-outline">
            <FormControlLabel
              value="Funded"
              control={<Radio />}
              label="Funded"
            />
            <FormControlLabel
              value="Bootstrapped"
              control={<Radio />}
              label="Bootstrapped"
            />
          </div>
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default Step3InterestsFunding;
