import { Formik } from "formik";
import { MDBInput } from "mdb-react-ui-kit";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import app_config from "../../config";
import Header from "./Header";

const ResetPassword = () => {
  const { verificationToken } = useParams();
  const navigate = useNavigate();

  const ResetPasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    repeatPassword: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Password is required"),
  });

  const userSubmit = async (formdata, { setSubmitting, setFieldValue }) => {
    try {
      setSubmitting(true);
      var req = {
        newPassword: formdata.newPassword,
        token: verificationToken,
      };
      const res = await fetch(`${app_config.apiurl}/users/reset-password`, {
        method: "POST",
        body: JSON.stringify(req),
        headers: { "Content-Type": "application/json" },
      });

      setSubmitting(false);

      if (res.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Your password reset is successfull",
        }).then(() => navigate("/main/login"));
      } else {
        let response = await res.json();
        let errorMessage =
          typeof response === "object" &&
          response !== null &&
          "error" in response
            ? response.error
            : "Please Enter right credentials";
        Swal.fire({
          icon: "error",
          title: "Error",
          text: errorMessage,
        });
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Unable to process your request. Please try again later",
      });
    }
  };

  return (
    <div>
      <Header />
      <div style={{ height: "90vh" }}>
        <div
          className="row"
          style={{ height: "40vh", backgroundColor: "#b0ff4b" }}
        />
        <div
          className="row pt-5 mx-auto justify-content-center align-items-center"
          style={{ marginTop: "-290px", width: "fit-content" }}
        >
          <div
            className="card pt-5"
            style={{ height: "fit-content", padding: "40px" }}
          >
            <div
              className="card-body"
              style={{
                height: "fit-content",
                width: "fit-content",
                padding: "0",
              }}
            >
              <p className="text-center h4">Reset Password</p>
              <hr />
              <Formik
                initialValues={{
                  newPassword: "",
                  repeatPassword: "",
                }}
                validationSchema={ResetPasswordSchema}
                onSubmit={userSubmit}
              >
                {({ values, handleSubmit, handleChange, errors, touched }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="row form-floating" onSubmit={handleSubmit}>
                      <div className="col">
                        <div className="form-outline mb-4 w-64">
                          <MDBInput
                            label="New Password"
                            type="password"
                            value={values.newPassword}
                            onChange={handleChange}
                            name="newPassword"
                          />
                          {errors.newPassword && touched.newPassword ? (
                            <div>{errors.newPassword}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="form-outline mb-4 w-64">
                      <MDBInput
                        label="Repeat Password"
                        type="password"
                        value={values.repeatPassword}
                        onChange={handleChange}
                        name="repeatPassword"
                      />
                      {errors.repeatPassword && touched.repeatPassword ? (
                        <div>{errors.repeatPassword}</div>
                      ) : null}
                    </div>

                    <div>
                      <button
                        className="btn"
                        type="submit"
                        style={{
                          backgroundColor: "#b0ff4b",
                          color: "black",
                          width: "100%",
                        }}
                      >
                        Reset Password
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
