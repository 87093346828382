const planData = {
  basic: {
    name: "Basic",
    features: [],
    price: 29,
  },
  regular: {
    name: "Regular",
    features: [],
    price: 59,
  },
  premium: {
    name: "Premium",
    features: [],
    price: 99,
  },
};

export default planData;
