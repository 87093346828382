import * as React from "react";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import app_config from "../../config";
import {
  experienceHelpingStartups,
  industryFocusOption,
  industryFocusOptionLookup,
  networkTypes,
  scoutSupportedStartupStages,
  scoutSupportedStartupStagesLookup,
} from "../../Constants";
import { countries, findCountryLabel } from "../commons/countries";
import FilterTabs from "../commons/filterTabs";
import MultiSelectDropdown from "../commons/MultiSelectDropDown";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import "./InvestorList.css";

const InvestorList = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [filteredInvestorList, setFilteredInvestorList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const profilesPerPage = 10;
  const [startupStageFilterVal, setStartupStageFilterVal] = useState(
    JSON.parse(sessionStorage.getItem("startupStageFilterVal")) || [],
  );
  const [industryFocusFilterVal, setIndustryFocusFilterVal] = useState(
    JSON.parse(sessionStorage.getItem("industryFocusFilterVal")) || [],
  );
  const [investorlocationFilterVal, setInvestorLocationFilterVal] = useState(
    JSON.parse(sessionStorage.getItem("investorlocationFilterVal")) || [],
  );
  const [networkTypeFilterVal, setNetworkTypeFilterVal] = useState(
    JSON.parse(sessionStorage.getItem("networkTypeFilterVal")) || [],
  );
  const [experienceFilterVal, setExperienceFilterVal] = useState(
    JSON.parse(sessionStorage.getItem("experienceFilterVal")) || [],
  );

  const [dropdownsOpen, setDropdownsOpen] = useState({
    startupStage: false,
    industryFocus: false,
    investorLocation: false,
    networkType: false,
    experience: false,
  });

  const [openPC, setOpenPC] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpenpc = (e) => {
    e.preventDefault();
    setOpenPC(true);
  };

  const handleClosepc = () => {
    setOpenPC(false);
  };

  const url = app_config.apiurl;
  const placeholder =
    "https://via.placeholder.com/640x400.png/a59090/000000?Text=640x400";

  const search = async (event) => {
    event.preventDefault();
    fetchData();
    setCurrentPage(1);
    setDropdownsOpen({
      startupStage: false,
      industryFocus: false,
      investorLocation: false,
      networkType: false,
      experience: false,
    });
    handleClosepc();
  };

  const fetchData = async () => {
    const searchUrl = new URL(`${url}/users/allscouts`);
    const params = new URLSearchParams();
    if (searchKeyword) {
      params.append("search", searchKeyword);
    }
    if (startupStageFilterVal) {
      params.append("startupStages", startupStageFilterVal);
    }
    if (industryFocusFilterVal) {
      params.append("industryFocus", industryFocusFilterVal);
    }
    if (investorlocationFilterVal) {
      params.append("location", investorlocationFilterVal);
    }
    if (networkTypeFilterVal) {
      params.append("networkType", networkTypeFilterVal);
    }
    if (experienceFilterVal) {
      params.append("experience", experienceFilterVal);
    }
    searchUrl.search = params.toString();
    console.log(searchUrl.toString());
    if (searchUrl.search.length === 0) {
      return;
    }
    const res = await fetch(searchUrl);
    const data = await res.json();
    setFilteredInvestorList(data.result);
  };

  useEffect(() => {
    function loadInitialData() {
      fetchData();
      setCurrentPage(1);
    }
    loadInitialData();
  }, []);

  const updateStartupStageFilterVal = (val) => {
    console.log(val);
    setStartupStageFilterVal(val);
    sessionStorage.setItem("startupStageFilterVal", JSON.stringify(val));
  };

  const updateIndustryFocusFilterVal = (val) => {
    console.log(val);
    setIndustryFocusFilterVal(val);
    sessionStorage.setItem("industryFocusFilterVal", JSON.stringify(val));
  };

  const updateInvestorLocationFilterVal = (val) => {
    console.log(val);
    setInvestorLocationFilterVal(val);
    sessionStorage.setItem("investorlocationFilterVal", JSON.stringify(val));
  };

  const updateNetworkTypeFilterVal = (val) => {
    console.log(val);
    setNetworkTypeFilterVal(val);
    sessionStorage.setItem("networkTypeFilterVal", JSON.stringify(val));
  };

  const updateExperienceFilterVal = (val) => {
    console.log(val);
    setExperienceFilterVal(val);
    sessionStorage.setItem("experienceFilterVal", JSON.stringify(val));
  };

  const isAvatarImageAvaiable = (imageUrl) => {
    console.log("imageUrl:" + imageUrl);
    return (
      imageUrl !== undefined &&
      imageUrl !== null &&
      imageUrl !== "undefined" &&
      imageUrl.trim().length > 0
    );
  };

  const displayList = () => {
    const indexOfLastProfile = currentPage * profilesPerPage;
    const indexOfFirstProfile = indexOfLastProfile - profilesPerPage;
    const currentProfiles = filteredInvestorList.slice(
      indexOfFirstProfile,
      indexOfLastProfile,
    );

    return currentProfiles.map((investor) => (
      <div key={investor._id} className="bg-[#fff] py-3 rounded">
        <div className="">
          <div className="flex flex-row items-center">
            <div className="col-md-4 w-[30%] flex flex-col gap-2 items-center justify-center ">
              <img
                className="px-1 py-1  rounded-full w-24 h-24"
                src={
                  isAvatarImageAvaiable(investor.investoravatar)
                    ? investor.investoravatar
                    : placeholder
                }
                alt=""
              />
              <div className="">
                <Link
                  type="button"
                  className="btn"
                  to={"/startup/investordetails/" + investor._id}
                  style={{
                    backgroundColor: "#b0ff4b",
                    color: "black",
                    padding: "8px",
                  }}
                >
                  View profile
                </Link>
              </div>
            </div>
            <div className="w-[70%]  px-1 m md:ml-16 lg:ml-1">
              <h1 className="text-lg md:text-xl capitalize">
                <strong>
                  {investor.firstName} {investor.lastName}
                </strong>
              </h1>
              <div className="flex flex-col mt-1">
                <p className="font-bold text-[#808080] text-sm ">
                  <strong>
                    {investor.currentPosition || "Not Available"},
                    {findCountryLabel(investor.location) || "Not Available"}
                  </strong>
                </p>
                {/* Experience */}
                {/* <div className="md:hidden flex flex-col md:flex-row md:items-center md:gap-2">
                  <p className="font-bold text-sm ">Experience:</p>
                  <p className="text-sm ">
                    {investor.experience || "Not Available"}
                  </p>
                </div> */}
                <div className="flex flex-col">
                  <p className="font-bold text-sm ">
                    Experience:{" "}
                    <span className="md:inline font-normal text-sm ">
                      {investor.experience || "Not Available"}
                    </span>
                  </p>
                </div>
                {/* Type of Investor Network */}
                {/* <div className="md:hidden flex flex-col md:flex-row md:items-center md:gap-2">
                  <p className="font-bold text-sm ">
                    Type of Investor Network:
                  </p>
                  <p className="text-sm ">
                    {investor.networkType || "Not Available"}
                  </p>
                </div> */}
                <div className="flex flex-col">
                  <p className="font-bold text-sm ">
                    Type of Investor Network:{" "}
                    <span className="md:inline font-normal text-sm ">
                      {investor.networkType || "Not Available"}
                    </span>
                  </p>
                </div>
                {/* Stage of startups you help */}
                {/* <div className="md:hidden flex flex-col md:flex-row md:items-center md:gap-2">
                  <p className="font-bold text-sm ">
                    Stage of startups you help:
                  </p>
                  <p className="text-sm ">
                    {scoutSupportedStartupStagesLookup[
                      investor.scoutSupportedStartupStage
                    ] || "Not Available"}
                  </p>
                </div> */}
                <div className="flex flex-col">
                  <p className="font-bold text-sm ">
                    Stage of startups you help:{" "}
                    <span className="md:inline font-normal text-sm ">
                      {scoutSupportedStartupStagesLookup[investor.stage] ||
                        "Not Available"}
                    </span>
                  </p>
                </div>
                {/* Industry Focus */}
                {/* <div className="md:hidden flex flex-col md:flex-row md:items-center md:gap-2">
                  <p className="font-bold text-sm ">
                    Industry Focus:
                  </p>
                  <p className="text-sm ">
                    {industryFocusOptionLookup[investor.industryFocus] ||
                      "Not Available"}
                  </p>
                </div> */}
                <div className="flex flex-col">
                  <p className="font-bold text-sm ">
                    Industry Focus:{" "}
                    <span className="md:inline font-normal text-sm ">
                      {industryFocusOptionLookup[investor.industryFocus] ||
                        "Not Available"}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  const renderPagination = () => {
    const pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(filteredInvestorList.length / profilesPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }

    const maxVisiblePages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(startPage + maxVisiblePages - 1, pageNumbers.length);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    return (
      <div className="flex justify-center mt-4">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          className={`px-2  py-1  mx-1 border text-sm rounded bg-white text-black ${
            currentPage === 1 ? "cursor-not-allowed opacity-50" : ""
          }`}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {startPage > 1 && (
          <button
            onClick={() => setCurrentPage(1)}
            className={`px-2  py-1  mx-1 border rounded ${
              currentPage === 1 ? "bg-black text-white" : "bg-white text-black"
            }`}
          >
            1
          </button>
        )}
        {startPage > 2 && (
          <button
            className="px-2  py-1  mx-1 border rounded bg-white text-black"
            disabled
          >
            ...
          </button>
        )}
        {pageNumbers.slice(startPage - 1, endPage).map((number) => (
          <button
            key={number}
            onClick={() => setCurrentPage(number)}
            className={`px-2  py-1  mx-1 border rounded ${
              currentPage === number
                ? "bg-black text-white"
                : "bg-white text-black"
            }`}
          >
            {number}
          </button>
        ))}
        {endPage < pageNumbers.length - 1 && (
          <span className="px-2  py-1  mx-1 border rounded bg-white text-black">
            ...
          </span>
        )}
        {endPage < pageNumbers.length && (
          <button
            onClick={() => setCurrentPage(pageNumbers.length)}
            className={`px-2  py-1  mx-1 border rounded ${
              currentPage === pageNumbers.length
                ? "bg-black text-white"
                : "bg-white text-black"
            }`}
          >
            {pageNumbers.length}
          </button>
        )}
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          className={`px-2 py-1  mx-1 border text-sm rounded bg-white text-black ${
            currentPage === pageNumbers.length
              ? "cursor-not-allowed opacity-50"
              : ""
          }`}
          disabled={currentPage === pageNumbers.length}
        >
          Next
        </button>
      </div>
    );
  };

  const clearFilters = () => {
    setStartupStageFilterVal([]);
    setIndustryFocusFilterVal([]);
    setInvestorLocationFilterVal([]);
    setNetworkTypeFilterVal([]);
    setExperienceFilterVal([]);
    sessionStorage.removeItem("startupStageFilterVal");
    sessionStorage.removeItem("industryFocusFilterVal");
    sessionStorage.removeItem("investorlocationFilterVal");
    sessionStorage.removeItem("networkTypeFilterVal");
    sessionStorage.removeItem("experienceFilterVal");
    fetchData();
  };

  return (
    <div>
      <div
        style={{
          marginBottom: "-135px",
          display: "flex",
        }}
        className="h-24 sm:h-60 md:justify-start justify-center"
      >
        <h1 className="text-black md:ml-7 text-center pt-4 text-4xl font-semibold mt-3 sm:mt-0">
          Kreedify pros
        </h1>
      </div>

      <form onSubmit={search} className="mt-40 sm:mt-0">
        <div className="px-4 flex flex-col gap-3">
          <Dialog
            fullScreen={fullScreen}
            open={openPC}
            onClose={handleClosepc}
            aria-labelledby="responsive-dialog-title"
            PaperProps={{
              sx: {
                width: "100%",
                height: "65vh",
                margin: 0,
              },
            }}
          >
            <div className="flex justify-between px-4 py-2 border-b border-[#000]">
              <div>
                <h2 className="text-2xl">Filters</h2>
              </div>
              <button onClick={handleClosepc} className="btn p-2 bg-[#b0ff4b]">
                <CloseIcon />
              </button>
            </div>
            <DialogContent dividers>
              <div className="flex flex-wrap  gap-1">
                <MultiSelectDropdown
                  options={scoutSupportedStartupStages.map((stg) => ({
                    label: stg.text,
                    val: stg.value,
                  }))}
                  label="Stage of Startups"
                  onSelectionChange={updateStartupStageFilterVal}
                  isOpen={dropdownsOpen.startupStage}
                  setIsOpen={(isOpen) =>
                    setDropdownsOpen((prev) => ({
                      ...prev,
                      startupStage: isOpen,
                    }))
                  }
                  selectedOptions={startupStageFilterVal}
                />

                <MultiSelectDropdown
                  options={industryFocusOption.map((ind) => ({
                    label: ind.text,
                    val: ind.value,
                  }))}
                  label="Industry Focus"
                  onSelectionChange={updateIndustryFocusFilterVal}
                  isOpen={dropdownsOpen.industryFocus}
                  setIsOpen={(isOpen) =>
                    setDropdownsOpen((prev) => ({
                      ...prev,
                      industryFocus: isOpen,
                    }))
                  }
                  selectedOptions={industryFocusFilterVal}
                />

                <MultiSelectDropdown
                  options={countries.map((cc) => ({
                    label: cc.name,
                    val: cc.value,
                  }))}
                  label="Location"
                  onSelectionChange={updateInvestorLocationFilterVal}
                  isOpen={dropdownsOpen.investorLocation}
                  setIsOpen={(isOpen) =>
                    setDropdownsOpen((prev) => ({
                      ...prev,
                      investorLocation: isOpen,
                    }))
                  }
                  selectedOptions={investorlocationFilterVal}
                />

                <MultiSelectDropdown
                  options={experienceHelpingStartups.map((cc) => ({
                    label: cc.text,
                    val: cc.value,
                  }))}
                  label="Experience"
                  onSelectionChange={updateExperienceFilterVal}
                  isOpen={dropdownsOpen.experience}
                  setIsOpen={(isOpen) =>
                    setDropdownsOpen((prev) => ({
                      ...prev,
                      experience: isOpen,
                    }))
                  }
                  selectedOptions={experienceFilterVal}
                />

                <MultiSelectDropdown
                  options={networkTypes.map((cc) => ({
                    label: cc.text,
                    val: cc.value,
                  }))}
                  label="Type of Investor Network"
                  onSelectionChange={updateNetworkTypeFilterVal}
                  isOpen={dropdownsOpen.networkType}
                  setIsOpen={(isOpen) =>
                    setDropdownsOpen((prev) => ({
                      ...prev,
                      networkType: isOpen,
                    }))
                  }
                  selectedOptions={networkTypeFilterVal}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <button
                onClick={search}
                className="ml-16 hidden md:block inline-flex justify-center w-[12rem] md:w-32 rounded-md border border-gray-300 shadow-sm px-3 py-2 bg-black text-sm font-medium text-white hover:bg-gray-700 focus:outline-none"
              >
                Search
              </button>

              <button
                className=" hidden md:block inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-black text-sm font-medium text-white hover:bg-gray-700 focus:outline-none"
                onClick={clearFilters}
              >
                Clear
              </button>
            </DialogActions>
          </Dialog>
          <div className="flex relative w-[18rem] md:w-[25rem] h-[3rem] md:h-[2.5rem]">
            <input
              className="pl-3 pr-2 py-1 md:py-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full rounded-lg border border-black"
              value={searchKeyword}
              placeholder="Name of Scout"
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
            <button
              type="submit"
              className=" h-full flex items-center justify-center pl-2"
            >
              <SearchIcon className="text-gray-500" />
            </button>
            <button
              onClick={handleClickOpenpc}
              className="hidden  px-2  py-1 mx-1 border rounded bg-white text-black md:flex gap-2 items-center"
            >
              <FilterAltOutlinedIcon />
              Filter
            </button>
          </div>

          {/* filter for mobile */}
          <FilterTabs
            listType="InvestorList"
            labels={[
              "Stage of Startups",
              "Industry Focus",
              "Location",
              "Type of Investor Network",
              "Experience",
            ]}
            setStartupStageFilterVal={updateStartupStageFilterVal}
            startupStageFilterVal={startupStageFilterVal}
            setIndustryFocusFilterVal={updateIndustryFocusFilterVal}
            industryFocusFilterVal={industryFocusFilterVal}
            setInvestorLocationFilterVal={updateInvestorLocationFilterVal}
            investorlocationFilterVal={investorlocationFilterVal}
            setNetworkTypeFilterVal={updateNetworkTypeFilterVal}
            networkTypeFilterVal={networkTypeFilterVal}
            experienceFilterVal={experienceFilterVal}
            setExperienceFilterVal={updateExperienceFilterVal}
            search={search}
            clearFilters={() => {
              clearFilters();
            }}
          />
        </div>
      </form>

      <div className="flex justify-start md:ml-6 ml-5 mb-4 ">
        {renderPagination()}
      </div>

      <div className="flex justify-center md:justify-start md:ml-2 mb-8">
        <div className="md:ml-4 w-[90%] md:w-[85%] 2xl:w-[65%]">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            {displayList()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestorList;
