//import { Button } from '@mui/base';
import { Card, CardContent, TextField, Button } from "@mui/material";
import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import app_config from "../../config";

export default function VerifyEmail() {
  const [loadingPage, setLoadingPage] = useState(true);
  const [error, setError] = useState(false);
  const { verificationToken } = useParams();
  const ref = useRef(null);
  const navigate = useNavigate();

  const validateToken = async () => {
    if (!verificationToken) {
      return;
    }
    setLoadingPage(true);
    const res = await fetch(
      `${app_config.apiurl}/users/verifyemail/${verificationToken}`,
    );
    setLoadingPage(false);
    if (res.status === 200) {
      setError(false);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Your email verified Successfully.",
      }).then((res) => navigate("/main/login"));
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (!ref.current) {
      console.log(verificationToken);
      if (verificationToken) {
        validateToken(verificationToken);
      }
    }
    return () => (ref.current = true);
  }, []);

  // validateToken();

  const loadingContent = () => {
    return (
      <div className="text-center mt-64">
        <h1 style={{ color: "#518f3d" }}>Please wait. Verifying you email.</h1>
      </div>
    );
  };

  const errorContent = (message) => {
    return (
      <div className="text-center mt-64">
        <h1 style={{ color: "#be1027" }}>{message}</h1>
      </div>
    );
  };

  const renderContent = () => {
    if (loadingPage) {
      return loadingContent();
    }
    if (error) {
      return errorContent("Invalid Request.  Please verify URL and try again");
    } else {
      return <></>;
    }
  };

  return renderContent();
}
