import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import ChatLayout from "../main/ChatLaylout";

const InvestorInbox = () => {
  const location = useLocation();
  const selectedChat = location.state?.selectedUser || null;
  const [inChatRoom, setInChatRoom] = useState(false);

  return (
    <div className="overflow-hidden">
      <div className="row align-items-center h-24 border-gray-200">
        {!inChatRoom || window.innerWidth >= 768 ? (
          <h1 className="text-black md:ml-16 ml-6 md:text-4xl text-3xl font-semibold sm:mt-0">
            Inbox
          </h1>
        ) : null}
      </div>
      <ChatLayout
        selectedChat={selectedChat}
        onChatChange={() => setInChatRoom(true)}
        onBack={() => setInChatRoom(false)}
      />
    </div>
  );
};

export default InvestorInbox;
