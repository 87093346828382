import React from "react";

const StartupProfile = () => {
  // return (
  //   <div className='card mx-auto' style={{ width: "60%" }}>
  //     <img
  //       className="rounded-circle shadow-1-strong mb-4"
  //       src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp"
  //       alt="avatar"
  //       style={{ width: 150 }}
  //     />
  //     <h3 >Ola Heden</h3>
  //   </div>
  // )
};

export default StartupProfile;
