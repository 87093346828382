import React, { useEffect, useState } from "react";
import "./Chat.css";
import { io } from "socket.io-client";
import app_config from "../../config";
import { useParams, useNavigate } from "react-router-dom";

const InvestorChat = () => {
  const url = app_config.apiurl;
  const navigate = useNavigate();
  const [socket, setSocket] = useState(io(url, { autoConnect: false }));
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(sessionStorage.getItem("investor")),
  );
  const { startupid } = useParams();
  useEffect(() => {
    socket.connect();
    socket.emit("addtocontact", currentUser._id);
    fetchChats();
  }, []);

  const checkStartuphasContact = async () => {
    const response = await fetch(`${url}/users/getbyid/${startupid}`);
    // console.log(response.status);
    const startupContacts = (await response.json()).result.contacts;
    console.log(startupContacts);
    // return startupContacts.includes(currentUser._id);
    return startupContacts.find((contact) => contact._id === currentUser._id);
  };

  const addInvestorToContact = async () => {
    const response = await fetch(`${url}/users/addtocontact/${startupid}`, {
      method: "PUT",
      body: JSON.stringify({
        contacts: currentUser._id,
      }),
      headers: { "Content-Type": "application/json" },
    });
    console.log(response.status);
    console.log("contact added");
  };

  const [messageList, setMessageList] = useState([]);
  const [receiverName, setReceiverName] = useState("");

  const [inputText, setInputText] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");

  const search = async (field) => {
    fetchChats(searchKeyword);
  };

  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const saveData = async (formdata) => {
    const res = await fetch(url + `/chat/add`, {
      method: "POST",
      body: JSON.stringify(formdata),
      headers: { "Content-Type": "application/json" },
    });

    console.log(res.status);
  };

  const fetchChats = async (keyword = "") => {
    const res = await fetch(
      url + "/chat/getchat/" + currentUser._id + "/" + startupid,
    );
    const chatsData = await res.json();
    console.log(chatsData);
    if (chatsData.recieverLastName) {
      setReceiverName(
        chatsData.recieverFirstName + " " + chatsData.recieverLastName,
      );
    } else {
      setReceiverName(chatsData.recieverFirstName);
    }

    if (keyword) {
      setMessageList(
        chatsData.result.filter((msg) =>
          msg.message.toLowerCase().includes(keyword.toLowerCase()),
        ),
      );
    } else {
      setMessageList(chatsData.result);
    }
  };

  const sendMessage = () => {
    if (!inputText.trim()) return;
    const temp = {
      users: [currentUser._id, startupid],
      sentBy: currentUser._id,
      to: startupid,
      date: new Date(),
      message: inputText,
      name: currentUser.name,
    };

    // sending msg to backend
    socket.emit("sendmsg", temp);

    setMessageList([...messageList, temp]);
    setInputText("");
    checkStartuphasContact().then((added) => {
      console.log(added);
      if (!added) addInvestorToContact();
    });
    saveData({
      users: [currentUser._id, startupid],
      sentBy: currentUser._id,
      date: new Date(),
      message: inputText,
      name: currentUser.name,
    });
  };

  socket.on("recmsg", (data) => {
    setMessageList([...messageList, data]);
  });

  return (
    <>
      <div
        style={{
          backgroundColor: "#b0ff4b",
          height: "40vh",
          marginBottom: "-300px",
        }}
      ></div>

      <div className="container d-flex flex-column justify-content-center align-items-center mt-10 p-5 ">
        <div className="card h-[40rem] w-[21rem] md:h-[31rem] md:w-[36rem]">
          <div
            className="card-header bg-success flex items-center justify-around py-4 w-[21rem] md:w-[36rem]"
            style={{ color: "white", marginLeft: "0" }}
          >
            <div className="col-2 col-md-2">
              <button onClick={() => navigate(-1)}>
                <i class="fa-solid fa-arrow-left text-xl"></i>
              </button>
            </div>
            <div className="col-8 col-md-8">
              {receiverName ? (
                <p className="m-0 h4 text-center">{receiverName}</p>
              ) : (
                <p className="m-0 h4 text-center">Loading chat</p>
              )}
            </div>
            <div className="col-2 col-md-2">
              <div>
                <button
                  onClick={handleClick}
                  className="m-0 float-end"
                  style={{ border: "none", background: "none" }}
                >
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/225/225287.png"
                    style={{ width: "20px" }}
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
          {isOpen && (
            <div
              className="col-md-8 input-group my-3 px-3 "
              style={{ maxWidth: "600px" }}
            >
              <input
                className="form-control  p-3"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
              />
              <button
                className="btn btn-primary input-group-append bg-success"
                style={{ color: "white" }}
                onClick={(e) => search("message")}
              >
                Search
              </button>
            </div>
          )}
          <div className="card-body chat-body  h-[31rem] w-[21rem] md:w-[36rem]">
            {messageList.map((obj) => (
              <>
                <div
                  className={
                    obj.sentBy === currentUser._id ? "msg-sent" : "msg-rec"
                  }
                >
                  <p className="m-0 text-black">{obj.message}</p>
                  <p
                    className="m-0 float-end text-black"
                    style={{ fontSize: 10 }}
                  >
                    {new Date(obj.date).toLocaleDateString()}{" "}
                    {new Date(obj.date).toLocaleTimeString()}
                  </p>
                </div>
              </>
            ))}
          </div>
          <div className="card-footer card-footer w-[21rem] md:w-[36rem]">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setInputText(e.target.value);
                }}
                value={inputText}
              />
              <button className="btn btn-success" onClick={sendMessage}>
                <i class="fas fa-paper-plane"></i>   Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestorChat;
