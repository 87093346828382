export function convertToReadableDate(dateString) {
  if (!dateString) {
    return dateString;
  }
  const date = new Date(dateString);

  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
}

export const checkProfileImageExist = (imageUrl) => {
  console.log("imageUrl:" + imageUrl);
  return (
    imageUrl !== undefined &&
    imageUrl !== null &&
    imageUrl !== "undefined" &&
    imageUrl.trim().length > 0
  );
};
