import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const CallToActionCard = () => {
  const navigate = useNavigate(); // Initialize the navigate function

  return (
    <div
      className="flex flex-col items-center justify-center py-10 px-10 md:px-16 rounded-lg shadow-lg mx-4 md:!mx-auto"
      style={{
        background: "linear-gradient(to bottom, #b0ff4b, white)",
        borderRadius: "20px", // Adds a custom border-radius
        maxWidth: "1000px", // Restricts the width
      }}
    >
      {/* Centered content */}
      <div className="text-center">
        <h2 className="text-3xl font-bold text-black mb-2">
          Join Kreedify Today!
        </h2>
        <p className="text-lg text-black mb-4">
          Start your journey to unlocking connections with investors through our
          trusted network of pros. Build relationships, raise funding, and grow
          your business faster.
        </p>
        <button
          className="bg-black text-white py-2 px-6 rounded-full text-lg font-medium"
          onClick={() => navigate("/main/signup")} // Navigate to /main/signup
        >
          Get Started Now
        </button>
      </div>
    </div>
  );
};

export default CallToActionCard;
