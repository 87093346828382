import React, { useEffect, useRef, useState } from "react";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import SendIcon from "@mui/icons-material/Send";
import EmojiPicker from "emoji-picker-react";

export default function ChatForm(props) {
  const [message, setMessage] = useState("");
  const textareaRef = useRef(null);
  const emojiPickerRef = useRef(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    props.handleFormSubmit(message);
    setMessage("");
    if (textareaRef.current) {
      textareaRef.current.style.height = "40px";
    }
  };

  const handleEmojiClick = (emojiObject) => {
    setMessage((prevMessage) => prevMessage + emojiObject.emoji);
  };

  const adjustTextareaHeight = (textarea) => {
    textarea.style.height = "auto"; // Reset height
    textarea.style.height = `${Math.min(textarea.scrollHeight, 120)}px`;
    textarea.style.overflowY =
      textarea.scrollHeight > 120 ? "scroll" : "hidden";
  };

  useEffect(() => {
    const textarea = textareaRef.current;

    const handleKeyDown = (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        handleFormSubmit(e);
      }
    };

    const handleClickOutside = (e) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(e.target) &&
        !textareaRef.current.contains(e.target) &&
        !e.target.closest(".emoji-toggle-button")
      ) {
        setShowEmojiPicker(false);
      }
    };

    if (textarea) {
      adjustTextareaHeight(textarea);
      textarea.addEventListener("keydown", handleKeyDown);
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      if (textarea) {
        textarea.removeEventListener("keydown", handleKeyDown);
      }
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [message]);

  return (
    <div className="relative">
      {showEmojiPicker && (
        <div ref={emojiPickerRef} className="absolute bottom-14 left-0 z-10">
          <EmojiPicker
            style={{ height: "350px", width: "300px" }}
            className="scrollbar-custom"
            onEmojiClick={handleEmojiClick}
          />
        </div>
      )}
      <form onSubmit={handleFormSubmit}>
        <div
          className="flex items-center w-full md:p-1 p-2 bg-white border-b border-gray-200 relative"
          style={{ borderColor: "rgb(229 231 235)" }}
        >
          <button
            onClick={(e) => {
              e.preventDefault();
              setShowEmojiPicker((prev) => !prev);
            }}
            className="emoji-toggle-button flex-shrink-0 mr-2"
          >
            <InsertEmoticonIcon
              className="h-7 w-7 text-blue-600"
              aria-hidden="true"
            />
          </button>

          <div className="flex-grow relative">
            <textarea
              ref={textareaRef}
              placeholder="Type a message"
              className="block w-full py-2 pl-4 outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg resize-none scrollbar-custom focus:ring-blue-500 focus:border-blue-500"
              name="message"
              required
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
                adjustTextareaHeight(e.target);
              }}
              style={{
                transition: "height 0.2s",
                minHeight: "40px", // Initial height
                maxHeight: "120px", // Max height for 5 lines
              }}
            />
          </div>
          <button type="submit" className="flex-shrink-0 ml-2">
            <SendIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
          </button>
        </div>
      </form>
    </div>
  );
}
