const convertListToLookupMap = (list, convertToNumber = false) => {
  var resp = list.reduce((map, obj) => {
    map[obj.value] = convertToNumber ? Number(obj.text) : obj.text;
    return map;
  }, {});
  console.log(resp);
  return resp;
};

export const industryFocusOption = [
  {
    text: "Artificial Intelligence (AI) and Machine Learning",
    value: "ai_n_ml",
  },
  { text: "Cybersecurity", value: "cybersecurity" },
  {
    text: "Blockchain and Cryptocurrency",
    value: "blockchain",
  },
  { text: "Internet of Things (IoT)", value: "iot" },
  { text: "Cloud Computing", value: "cloud computing" },
  {
    text: "Digital Health and Health Tech",
    value: "digital_health_and_tech",
  },
  { text: "Biotechnology", value: "biotechnology" },
  { text: "Medical Devices", value: "medical_devices" },
  { text: "Pharmaceuticals", value: "pharmaceuticals" },
  { text: "Telemedicine", value: "telemedicine" },
  {
    text: "Fintech (Financial Technology)",
    value: "fintech (Financial Technology)",
  },
  { text: "Insurtech (Insurance Technology)", value: "insurtech" },
  { text: "Cryptocurrency Exchanges", value: "cryptocurrency Exchanges" },
  { text: "E-commerce", value: "e-commerce" },
  { text: "Director-to-Consumer Brands", value: "director-to-consumer" },
  { text: "Food and Beverage", value: "food_and_beverage" },
  { text: "Personal Care and Beauty", value: "personal_care_and_beauty" },
  { text: "Streaming Services", value: "streaming" },
  { text: "Gaming and Esports", value: "gaming_and_esports" },
  {
    text: "Content Creation and Distribution",
    value: "content_creation_and_distribution",
  },
  {
    text: "Ride-sharing and Car-sharing",
    value: "ride-sharing_and_car-sharing",
  },
  { text: "Electric Vehicles (EVs)", value: "ev" },
  { text: "Autonomous Vehicles", value: "autonomous_vehicles" },
  { text: "Urban Mobility Solutions", value: "urban_mobility_solutions" },
  { text: "Edtech (Educational Technology)", value: "edtech" },
  { text: "Online Learning Platforms", value: "online learning platforms" },
  { text: "Tutoring Services", value: "tutoring services" },
  {
    text: "Clean Energy and Renewable Energy",
    value: "clean_energy",
  },
  {
    text: "Sustainable Products and Services",
    value: "sustainable_products",
  },
  {
    text: "Energy Storage and Management",
    value: "energy_storage",
  },
  {
    text: "Brick and Mortar Innovations",
    value: "brick_and_mortar_innovations",
  },
  { text: "Retail Tech", value: "retail tech" },
  {
    text: "Customer Experince Solutions",
    value: "custome_experince_solutions",
  },
  {
    text: "HR Tech (Human Resources Technology)",
    value: "hr_tech",
  },
  { text: "Recruitment Platforms", value: "recruitment platforms" },
  {
    text: "Employee Engagement Solutions",
    value: "employee_engagement_solutions",
  },
  { text: "Legal Tech", value: "legal_tech" },
  {
    text: "Contract Management Solutions",
    value: "contract_management_solutions",
  },
  { text: "Online Legal Services", value: "online_legal_services" },
  { text: "Travel Tech", value: "travel_tech" },
  {
    text: "Accommodation and Experience Platforms",
    value: "accommodation_and_experience_platforms",
  },
  { text: "Online Travel Agencies", value: "online travel agencies" },
  { text: "Sports", value: "sports" },
  { text: "Sports Tech", value: "sports tech" },
];

export const industryFocusOptionLookup =
  convertListToLookupMap(industryFocusOption);

export const startupStages = [
  { text: "Ideation", value: "Ideation" },
  { text: "Validation", value: "Validation" },
  { text: "Early Traction", value: "EarlyTraction" },
  { text: "Scaling", value: "Scaling" },
];
// export const startupStagesLookup = convertListToLookupMap(startupStages);

export const scoutSupportedStartupStages = [
  { text: "Pre-seed", value: "pre-seed" },
  { text: "Seed", value: "seed" },
  { text: "Series A", value: "seriesA" },
  { text: "Series B", value: "seriesB" },
  { text: "Series C", value: "seriesC" },
  { text: "IPO", value: "ipo" },
  { text: "Pre-IPO", value: "preIPO" },
  { text: "Post IPO", value: "postIPO" },
];

export const scoutSupportedStartupStagesLookup = convertListToLookupMap(
  scoutSupportedStartupStages,
);

export const networkTypes = [
  { text: "Angel", value: "angel" },
  { text: "VC", value: "vc" },
  { text: "Family office", value: "familyoffice" },
  { text: "HNI", value: "hni" },
  { text: "Private equity firms", value: "privateequity" },
];

export const networkTypeLookup = convertListToLookupMap(networkTypes);

export const experienceHelpingStartups = [
  { value: "1-3", text: "1-3" },
  { value: "3-5", text: "3-5" },
  { value: "5-7", text: "5-7" },
  { value: "10+", text: "10+" },
];

export const experienceHelpingStartupsLookup = convertListToLookupMap(
  experienceHelpingStartups,
  true,
);

export const startupIndustries = [
  { value: "advertising", text: "Advertising" },
  { value: "artAndPhotography", text: "Art And Photography" },
  { value: "chemicals", text: "Chemicals" },
  {
    value: "telecommunicationAndNetworking",
    text: "Telecommunication And Networking",
  },
  { value: "construction", text: "Construction" },
  { value: "agriculture", text: "Agriculture" },
  { value: "events", text: "Event" },
  { value: "fashion", text: "Fashion" },
  { value: "education", text: "Education" },
  { value: "healthcareAndLifesciences", text: "Healthcare And Lifesciences" },
  { value: "itServices", text: "IT Services" },
  { value: "marketing", text: "Marketing" },
  { value: "others", text: "Others" },
];

export const startupIndustriesLookup =
  convertListToLookupMap(startupIndustries);

export const fetchStarupStageLabel = (stageList) => {
  return stageList
    .map((stg) => (startupStages.find((s) => s.value === stg) || {}).text)
    .join(",");
};
