import React, { useState, useEffect } from "react";
import classNames from "classnames";
import debounce from "lodash.debounce";

const MultiSelectDropdown = ({
  options,
  onSelectionChange,
  label,
  isOpen,
  setIsOpen,
  selectedOptions,
}) => {
  const [localSelectedOptions, setLocalSelectedOptions] =
    useState(selectedOptions);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [inputValue, setInputValue] = useState("");

  const isLocationField = label === "Location"; // Check if label is "Location"

  // Update local selected options and clear input when parent clears selectedOptions
  useEffect(() => {
    setLocalSelectedOptions(selectedOptions);
    if (selectedOptions.length === 0) {
      setInputValue(""); // Clear the input value
      setFilteredOptions(options); // Reset the filtered options
    }
  }, [selectedOptions, options]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    const newSelectedOptions = localSelectedOptions.includes(option)
      ? localSelectedOptions.filter((item) => item !== option)
      : [...localSelectedOptions, option];
    setLocalSelectedOptions(newSelectedOptions);
    onSelectionChange(newSelectedOptions);
  };

  const handleInputChange = (e) => {
    const input = e.target.value;
    setInputValue(input);

    if (isLocationField) {
      if (input === "") {
        // Reset the filtered options when input is cleared
        setFilteredOptions(options);
      } else {
        const debouncedFilterOptions = debounce((input) => {
          setFilteredOptions(
            options.filter((option) =>
              option.label.toLowerCase().includes(input.toLowerCase()),
            ),
          );
        }, 300);
        debouncedFilterOptions(input);
      }
    } else {
      setFilteredOptions(options);
    }
  };

  return (
    <div className="relative inline-block text-left min-w-[12rem] md:w-48">
      <div>
        <button
          type="button"
          onClick={toggleDropdown}
          className={classNames(
            "inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none",
            {
              "w-60": label === "Type of Investor Network",
              "w-48": label !== "Type of Investor Network",
            },
          )}
        >
          {label}
          <span className="ml-2">{isOpen ? "▲" : "▼"}</span>
        </button>
      </div>

      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
          <div className="py-1 max-h-60 overflow-y-auto">
            {isLocationField && (
              <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                placeholder="Search location"
                className="w-[90%] mx-2 px-2 py-2 border border-gray-300 rounded-md mb-2"
              />
            )}
            {filteredOptions.map((option) => (
              <div key={option.val} className="flex items-center px-4 py-2">
                <input
                  type="checkbox"
                  checked={localSelectedOptions.includes(option.val)}
                  onChange={() => handleOptionClick(option.val)}
                  className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                />
                <label className="ml-3 block text-sm leading-5 text-gray-700">
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
