import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Services from "./Services";
import Footer from "./Footer";
const Main = () => {
  const location = useLocation();

  const shouldShowFooter = () => {
    return !["/main/signup", "/main/login"].includes(location.pathname);
  };

  return (
    <div className="module">
      <Outlet />
      <Services />
      {shouldShowFooter() && <Footer />}
    </div>
  );
};

export default Main;
