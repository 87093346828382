import React, { useState } from "react";
import { Link } from "react-router-dom";
import AlphabetAvatar from "../AlphabetAvatar";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { checkProfileImageExist } from "../../util.js";

const MyNetwork = ({ approvedRequests, pendingRequests }) => {
  const [activeTab, setActiveTab] = useState("approved");

  const renderApprovedTab = () => (
    <div className="py-2 px-2 mb-4 md:-mt-8 -ml-4 md:-ml-12 md:!py-10">
      {approvedRequests.length === 0 ? (
        <p className="text-center mt-8 md:ml-32 mb-4">
          Yet to create your network.
        </p>
      ) : (
        approvedRequests.map((request) => (
          <div
            key={request._id}
            className="flex flex-col !ml-9 md:-ml-8 md:w-[40rem] gap-2 py-4 px-2 md:px-0 sm:p-6 bg-white mx-2 md:mx-auto rounded-xl shadow-md mb-3 md:mb-5"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="flex items-center mr-4">
                  {checkProfileImageExist(request.investoravatar) ? (
                    <img
                      className="h-16 w-16 md:h-16 md:w-16 rounded-full"
                      src={request.investoravatar}
                      alt="avatar"
                    />
                  ) : (
                    <AlphabetAvatar
                      alphabet={request.firstName.charAt(0)}
                      className="!h-16 !w-16 md:h-16 md:w-16"
                    />
                  )}
                </div>

                <h2 className="text-base md:text-xl font-medium capitalize">
                  {request.firstName} {request.lastName}
                </h2>
              </div>

              <div className="md:hidden flex-shrink-0 mr-3">
                <Link
                  className="bg-white border-2 border-black p-2 rounded-full flex items-center justify-center h-7 w-7"
                  to={"/startup/investordetails/" + request._id}
                >
                  <ArrowForwardIcon />
                </Link>
              </div>

              <div className="hidden md:block mr-3">
                <Link
                  className="bg-white border-2 border-black text-black p-2 rounded"
                  to={"/startup/investordetails/" + request._id}
                >
                  View Profile
                </Link>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );

  const renderPendingTab = () => (
    <div className="py-2 px-2 mb-4 md:-mt-8 -ml-4 md:-ml-12 md:!py-10">
      {pendingRequests.length === 0 ? (
        <p className="text-center mt-8">No Pending requests</p>
      ) : (
        pendingRequests.map((request) => (
          <div
            key={request._id}
            className="flex flex-col !ml-9 md:-ml-8 md:w-[40rem] gap-2 py-4 px-2 md:px-0 sm:p-6 bg-white mx-2 md:mx-auto rounded-xl shadow-md mb-3 md:mb-5"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="flex items-center mr-4">
                  {checkProfileImageExist(request.investoravatar) ? (
                    <img
                      className="h-16 w-16 md:h-16 md:w-16 rounded-full"
                      src={request.investoravatar}
                      alt="avatar"
                    />
                  ) : (
                    <AlphabetAvatar
                      alphabet={request.firstName.charAt(0)}
                      className="!h-16 !w-16 md:h-16 md:w-16"
                    />
                  )}
                </div>

                <h2 className="text-base md:text-xl font-medium capitalize">
                  {request.firstName} {request.lastName}
                </h2>
              </div>

              <div className="md:hidden flex-shrink-0 mr-3">
                <Link
                  className="bg-white border-2 border-black p-2 rounded-full flex items-center justify-center h-7 w-7"
                  to={"/startup/investordetails/" + request._id}
                >
                  <ArrowForwardIcon />
                </Link>
              </div>

              <div className="hidden md:block mr-3">
                <Link
                  className="bg-white border-2 border-black text-black p-2 rounded"
                  to={"/startup/investordetails/" + request._id}
                >
                  View Profile
                </Link>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );

  return (
    <div className="py-2 px-2 mb-4 md:-mt-8 -ml-4 md:ml-4 md:!py-10">
      {/* Tab Navigation */}
      <div className="flex justify-center md:justify-start mb-4">
        <button
          className={`px-6 py-2 rounded-l-full ${activeTab === "approved" ? "bg-black text-white" : "bg-white text-black border-2 border-black"}`}
          onClick={() => setActiveTab("approved")}
        >
          Approved
        </button>
        <button
          className={`px-6 py-2 rounded-r-full ${activeTab === "pending" ? "bg-black text-white" : "bg-white text-black border-2 border-black"}`}
          onClick={() => setActiveTab("pending")}
        >
          Pending
        </button>
      </div>

      {/* Tab Content */}
      {activeTab === "approved" ? renderApprovedTab() : renderPendingTab()}
    </div>
  );
};

export default MyNetwork;
