import { Formik } from "formik";
import { MDBInput } from "mdb-react-ui-kit";
import { useState } from "react";
import Swal from "sweetalert2";
import * as Yup from "yup";
import app_config from "../../config";
import {
  industryFocusOption,
  experienceHelpingStartups,
  networkTypes,
  scoutSupportedStartupStages,
} from "../../Constants";
import { countries } from "../commons/countries";

export default function ForScoutForm() {
  const [isSubmitting, setSubmitting] = useState(false);
  const userSubmit = async (formdata, { resetForm }) => {
    try {
      console.log(formdata);
      setSubmitting(true);
      const res = await fetch(`${app_config.apiurl}/users/add-scout-interest`, {
        method: "POST",
        body: JSON.stringify(formdata),
        headers: { "Content-Type": "application/json" },
      });

      setSubmitting(false);

      if (res.status === 201) {
        resetForm();
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Thanks for showing interest. Our team will reach out to you soon.",
        });
      } else {
        let data = await res.json();
        let errorMsg =
          "error" in data
            ? data.error
            : "Unable to submit your request. Please try again later";
        Swal.fire({
          icon: "error",
          title: "Error",
          text: errorMsg,
        });
      }
    } catch (err) {
      setSubmitting(false);
      console.log("Caught exeception");
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Unable to submit your request. Please try again later",
      });
    }
  };

  const ContactSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2)
      .required("First Name is required")
      .max(50, "First Name must be atmost 50 characters"),
    lastName: Yup.string()
      .min(2)
      .required("Last Name is required")
      .max(50, "Last Name must be atmost 50 characters"),
    linkedInUrl: Yup.string().min(2).required("LinkedIn URL is required"),
    email: Yup.string()
      .min(2)
      .email("Invalid email")
      .required("Email is required"),
    currentPosition: Yup.string()
      .min(2)
      .max(100, "Current position limit exceeded")
      .required("Current position is required"),
    experience: Yup.string()
      .min(2)
      .max(50, "Experience Helping Startup, limit exceeded")
      .required("Experience Helping Startup, is required"),
    expertise: Yup.string()
      .min(2)
      .max(500, "Expertise limit exceeded")
      .required("Expertise is required"),
    industryFocus: Yup.string()
      .min(2)
      .max(50, "Industry focus, limit exceeded")
      .required("Industry focus, is required"),
    bio: Yup.string()
      .min(2)
      .max(500, "Bio limit exceeded")
      .required("Your bio is required"),
    stage: Yup.string().required("Stage of startups is required"),
    location: Yup.string()
      .min(2)
      .max(14, "Location limit exceeded")
      .required("Location is required"),
    networkType: Yup.string().required("Type of investor network is required"),
  });

  return (
    <div>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          linkedInUrl: "",
          email: "",
          currentPosition: "",
          experience: "",
          expertise: "",
          industryFocus: "",
          bio: "",
          stage: "",
          networkType: "",
          location: "",
        }}
        validationSchema={ContactSchema}
        onSubmit={userSubmit}
      >
        {({ values, handleSubmit, handleChange, errors, touched }) => (
          <div className="!m-6 md:!my-10 md:!mx-12">
            <form
              className="card !p-8 md:!py-10 md:!px-20"
              style={{ height: "fit-content" }}
              onSubmit={handleSubmit}
            >
              <div className="md:px-2">
                <p className="h4 text-3xl py-3 text-center">
                  Sign up as Kreedify Pro
                </p>

                {/* <div className='row mt-5'> */}
                <div className="d-flex flex-column align-items-center">
                  <div className="col-12 col-md-5 mb-2 md:!mb-0">
                    <div className="  form-outline mb-3">
                      <MDBInput
                        label="First Name"
                        type="text"
                        maxLength={15}
                        value={values.firstName}
                        onChange={handleChange}
                        name="firstName"
                      />
                      {errors.firstName && touched.firstName ? (
                        <div className="text-deny">{errors.firstName}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 col-md-5 mb-2 md:!mb-0">
                    <div className="  form-outline mb-3">
                      <MDBInput
                        label="Last Name"
                        type="text"
                        maxLength={15}
                        value={values.lastName}
                        onChange={handleChange}
                        name="lastName"
                      />
                      {errors.lastName && touched.lastName ? (
                        <div className="text-deny">{errors.lastName}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 col-md-5 mb-2 md:!mb-0">
                    <div className="  form-outline mb-3">
                      <MDBInput
                        label="LinkedIn URL"
                        type="url"
                        value={values.linkedInUrl}
                        onChange={handleChange}
                        name="linkedInUrl"
                      />
                      {errors.linkedInUrl && touched.linkedInUrl ? (
                        <div className="text-deny">{errors.linkedInUrl}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 col-md-5 mb-2 md:!mb-0">
                    <div className="  form-outline mb-3">
                      <MDBInput
                        label="Current position"
                        type="text"
                        value={values.currentPosition}
                        onChange={handleChange}
                        name="currentPosition"
                      />
                      {errors.currentPosition && touched.currentPosition ? (
                        <div className="text-deny">
                          {errors.currentPosition}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 col-md-5 mb-2 md:!mb-0">
                    <div className="col-md-6 form-outline mb-3">
                      <select
                        id="experience"
                        onChange={handleChange}
                        value={values.experience}
                        className="bg-gray-50 border border-black-900 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="">Experience Helping Startup</option>
                        {experienceHelpingStartups.map((option) => {
                          return (
                            <option key={option.value} value={option.value}>
                              {option.text}
                            </option>
                          );
                        })}
                      </select>
                      {errors.experience && touched.experience ? (
                        <div className="text-deny">{errors.experience}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 col-md-5 mb-2 md:!mb-0">
                    <div className=" col-md-6 form-outline mb-3">
                      <MDBInput
                        label="Your expertise"
                        type="text"
                        value={values.expertise}
                        onChange={handleChange}
                        name="expertise"
                      />
                      {errors.expertise && touched.expertise ? (
                        <div className="text-deny">{errors.expertise}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-5 mb-2 md:!mb-0">
                    <div className="col-md-6 form-outline mb-3">
                      <select
                        id="industryFocus"
                        onChange={handleChange}
                        value={values.industryFocus}
                        className="bg-gray-50 border border-black-900 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="">Your Industry focus</option>
                        {industryFocusOption.map((option) => {
                          return (
                            <option key={option.value} value={option.value}>
                              {option.text}
                            </option>
                          );
                        })}
                      </select>
                      {errors.industryFocus && touched.industryFocus ? (
                        <div className="text-deny">{errors.industryFocus}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 col-md-5 mb-2 md:!mb-0">
                    <div className="col-md-6 form-outline mb-3">
                      <MDBInput
                        label="More bio"
                        type="text"
                        value={values.bio}
                        onChange={handleChange}
                        name="bio"
                      />
                      {errors.bio && touched.bio ? (
                        <div className="text-deny">{errors.bio}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 col-md-5 mb-2 md:!mb-0">
                    <div className="col-md-6 form-outline mb-3">
                      <select
                        id="stage"
                        onChange={handleChange}
                        value={values.stage}
                        class="bg-gray-50 border border-black-900 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="">Stage of startup’s you help</option>
                        {scoutSupportedStartupStages.map((option) => {
                          return (
                            <option key={option.value} value={option.value}>
                              {option.text}
                            </option>
                          );
                        })}
                      </select>
                      {errors.stage && touched.stage ? (
                        <div className="text-deny">{errors.stage}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 col-md-5 mb-2 md:!mb-0">
                    <div className="col-md-6 form-outline mb-3">
                      <select
                        id="networkType"
                        onChange={handleChange}
                        value={values.networkType}
                        class="bg-gray-50 border border-black-900 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="">Type of Investor Network</option>
                        {networkTypes.map((option) => {
                          return (
                            <option id={option.value} value={option.value}>
                              {option.text}
                            </option>
                          );
                        })}
                      </select>
                      {errors.networkType && touched.networkType ? (
                        <div className="text-deny">{errors.networkType}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-5 mb-2 md:!mb-0">
                    <div className=" col-md-6 form-outline mb-3">
                      <select
                        id="location"
                        onChange={handleChange}
                        value={values.location}
                        className="bg-gray-50 border border-black-900 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="">Location</option>
                        {countries.map((option) => {
                          return (
                            <option key={option.value} value={option.value}>
                              {option.name}
                            </option>
                          );
                        })}
                      </select>
                      {errors.location && touched.location ? (
                        <div className="text-deny">{errors.location}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-5 mb-2 md:!mb-0">
                    <div className=" col-md-6 form-outline mb-3">
                      <MDBInput
                        label="Email"
                        type="email"
                        value={values.email}
                        onChange={handleChange}
                        name="email"
                      />
                      {errors.email && touched.email ? (
                        <div className="text-deny">{errors.email}</div>
                      ) : null}
                    </div>
                  </div>

                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-block"
                    style={{
                      backgroundColor: "#b0ff4b",
                      color: "black",
                      width: "200px",
                    }}
                    //onSubmit={userSubmit}
                  >
                    {isSubmitting ? (
                      <span
                        class="spinner-border spinner-border-sm"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      "Submit"
                    )}
                    {isSubmitting}
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </Formik>
    </div>
  );
}
